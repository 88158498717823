import React from "react";
import PageHelmet from "../../../../components/pageHelmet";
import TopNav from "../../components/topNav";
import Footer from "../../components/footer";
import ResourcesLayout from "../../components/resourcesLayout";
import { HowToUseSystem } from "./howToUseSystem";

export default function HowToUseOurSystem() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - How To Use Our System"} />

      <TopNav />

      <ResourcesLayout>
        <HowToUseSystem />
      </ResourcesLayout>

      <Footer />
    </>
  );
}
