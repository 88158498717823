import { useState } from "react";
import { Link } from "react-router-dom";
import {
  AtSymbolIcon,
  UserCircleIcon,
  DevicePhoneMobileIcon,
  UserGroupIcon,
  BanknotesIcon,
  ArrowLeftIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import { ConfirmationModal, AlertModal } from "../ui/confirmationModals";
import { CreateButton } from "../ui/buttons";

export default function MentorForm() {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    students: "",
    earnings: "",
  });

  const [errors, setErrors] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleConfirm = () => {
    setShowConfirmModal(false);

    setFormData({
      fullname: "",
      email: "",
      phone: "",
      students: "",
      earnings: "",
    });

    setShowSuccessMessage(true);

    setTimeout(() => setShowSuccessMessage(false), 3000);
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
    setShowSuccessMessage(false);
  };

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData({ ...formData, [name]: value });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const handleSubmit = (ev) => {
    if (ev) ev.preventDefault();
    const validationErrors = {};

    if (!formData.fullname.trim()) {
      validationErrors.fullname = "Full name is required";
    }

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = "Email must be valid!";
    }

    if (!formData.phone) {
      validationErrors.phone = "Phone number is required!";
    } else if (formData.phone.length !== 10) {
      validationErrors.phone = "Phone number must be 10 digits!";
    } else if (!/^\d+$/.test(formData.phone)) {
      validationErrors.phone = "Phone number must contain only digits!";
    }

    if (!formData.students) {
      validationErrors.students = "Students is required";
    } else if (isNaN(Number(formData.students))) {
      validationErrors.students = "Students must be a number";
    }

    if (!formData.earnings) {
      validationErrors.earnings = "Earnings is required";
    } else if (isNaN(Number(formData.earnings))) {
      validationErrors.earnings = "Earnings must be a number";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setShowConfirmModal(true);
    }
  };

  return (
    <>
      <div className="md:flex pt-5 justify-between items-center flex-row-reverse lg:pt-0">
        <Link
          to={"/manage-mentors"}
          className="hidden md:block mr-5 rounded-full border bottom-0 hover:bg-gray-100"
        >
          <ArrowLeftIcon className="h-6 w-7 " />
        </Link>
        <div className="text-xl font-semibold lg:pt-0 text-[#0B133E]">
          <Link to={"/manage-mentors"} className="text-gray-600 font-normal">
            Manage Mentors
          </Link>{" "}
          / Create Mentor
        </div>{" "}
      </div>

      <form onSubmit={handleSubmit}>
        <div className="rounded-md bg-gray-50 mt-5 p-4 md:p-6 font-medium text-[#0B133E]">
          <label htmlFor="fullname">Full Name</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.fullname}
              id="fullname"
              name="fullname"
              type="text"
              placeholder="Enter mentor's full name"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
            />
            <UserCircleIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500 "
              style={{ strokeWidth: 2 }}
            />
            {errors.fullname && (
              <span className="error-message">{errors.fullname}</span>
            )}
          </div>

          <label htmlFor="email">Email</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.email}
              id="email"
              name="email"
              type="text"
              placeholder="Enter mentor's email"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
            />
            <AtSymbolIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
            {errors.email && (
              <span className="error-message">{errors.email}</span>
            )}
          </div>

          <label htmlFor="phone">Phone Number</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.phone}
              id="phone"
              name="phone"
              type="tel"
              placeholder="Enter mentor's phone number"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
            />
            <DevicePhoneMobileIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
            {errors.phone && (
              <span className="error-message">{errors.phone}</span>
            )}
          </div>

          <label htmlFor="students">Students</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.students}
              id="students"
              name="students"
              type="number"
              placeholder="Enter number of students"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
            />
            <UserGroupIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
            {errors.students && (
              <span className="error-message">{errors.students}</span>
            )}
          </div>

          <label htmlFor="earnings">Earnings</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.earnings}
              id="earnings"
              name="earnings"
              type="number"
              step="0.01"
              placeholder="Mentor's earnings"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
            />
            <BanknotesIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
            {errors.earnings && (
              <span className="error-message">{errors.earnings}</span>
            )}
          </div>
        </div>
        <div className="mt-5 mb-10 flex justify-end gap-5">
          <Link to="/manage-mentors">
            <CreateButton
              className="flex flex-row-reverse py-3 bg-sky-200 hover:bg-sky-300"
              children={"Cancel"}
              icon={
                <ArrowLeftCircleIcon
                  className="w-5 h-5"
                  style={{ strokeWidth: 2 }}
                />
              }
            />
          </Link>

          <CreateButton
            className={" bg-sky-200 hover:bg-sky-300"}
            children={"Confirm Create"}
            icon={
              <ArrowRightCircleIcon
                className="h-5 w-5"
                style={{ strokeWidth: 2 }}
              />
            }
          />
        </div>
      </form>

      {showConfirmModal && (
        <ConfirmationModal
          children={`Confirm Creation of Mentor?`}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      )}

      {showSuccessMessage && (
        <AlertModal
          children={`Mentor Created Successfully!`}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
}
