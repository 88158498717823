import React from "react";
import { NavLink } from "react-router-dom";
import { ProfileSideNavLinks } from "./profileSideNavLinks";

export default function ProfileSideNav() {
  return (
    <>
      <ul className="mt-[60px] w-[93%] m-auto">
        {ProfileSideNavLinks.map((link) => {
          const { name, href, icon } = link;

          return (
            <li key={name}>
              <NavLink
                to={`${href}`}
                className={({ isActive }) =>
                  `text-[#0B133E] flex gap-2 list-none items-center justify-start font-semibold w-full rounded-md py-3 my-5 pl-10 transition-transform duration-300 ease-in-out ${
                    isActive
                      ? "bg-sky-300"
                      : "bg-white hover:bg-sky-100 hover:scale-105 hover:shadow-lg"
                  }`
                }
              >
                {icon}
                {name}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
}
