import React from "react";
import PageHelmet from "../../../../../components/pageHelmet";
import TopNav from "../../../components/topNav";
import Footer from "../../../components/footer";
import SideNavBarLayout from "../../../components/sideNavBarLayout";
import MobileNavBarLayout from "../../../components/mobileNavBarLayout";
import LogoutButton from "../../../components/logoutButton";
import ProfileSideNav from "../ui/profileSideNav";
import ProfileMobileNav from "../ui/profileMobileNav";
import DashboardPageLayout from "../../..";
import Settings from "../ui/settings";

export default function ProfileSettings() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Manage Your Settings"} />
      <TopNav />
      <DashboardPageLayout>
        <div className="flex flex-col lg:flex-row">
          <SideNavBarLayout>
            <ProfileSideNav />
            <LogoutButton />
          </SideNavBarLayout>
          <MobileNavBarLayout>
            <ProfileMobileNav />
          </MobileNavBarLayout>
          <div className="px-5 my-[50px] md:mt-[110px] md:px-10 lg:mt-[60px] lg:h-screen lg:flex-1">
            <Settings />
          </div>
        </div>
      </DashboardPageLayout>
      <Footer />
    </>
  );
}
