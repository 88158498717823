import React from "react";
import { Link } from "react-router-dom";

export function TermsAndConditions() {
  return (
    <>
      <div className="text-[#0B133E]">
        <div className="pt-5">
          <h1 className="text-2xl font-bold pb-5">Terms And Conditions</h1>
          <h2 className="font-semibold text-xl pb-1">
            Ts & Cs Of Using Our System
          </h2>
          <ol className="list-number pl-5" style={{ listStyleType: "decimal" }}>
            <li>Introduction: Welcome to Robotrader</li>
          </ol>
        </div>

        <div className="py-10">
          <h2 className="font-semibold text-xl pb-1">Privacy Policies</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
            ipsum esse perspiciatis eos veritatis quae, doloremque et, sed
            impedit, dolor aperiam asperiores sapiente dolorem fugit magnam!
            Earum, voluptatem. Placeat, eius!
          </p>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">
            Frequently Asked Questions
          </h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni odit
            ea esse provident voluptas illum sunt quam! Impedit nesciunt
            deserunt expedita neque vero doloremque sit laudantium voluptas
            velit accusantium! Omnis mollitia corporis aliquid deserunt deleniti
            voluptatibus nisi sit, ullam dicta architecto! Libero officia
            tenetur a?
          </p>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">Lorem, ipsum dolor</h2>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta
            consequatur hic suscipit sunt officia libero tempore ratione
            similique temporibus. Facilis.
          </p>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">Get in Touch</h2>
          <Link
            to={"https://wa.me/0614361548"}
            target="_blank"
            className="font-semibold text-sky-800 hover:underline underline-offset-4"
          >
            {" "}
            <em>WhatsApp at +27 61 436 1548.</em>
          </Link>{" "}
        </div>
      </div>
    </>
  );
}
