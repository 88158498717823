import React from "react";
import PageHelmet from "../../../../components/pageHelmet";
import TopNav from "../../components/topNav";
import Footer from "../../components/footer";
import ResourcesLayout from "../../components/resourcesLayout";
import { TermsAndConditions } from "./termsAndConditions";

export default function TermsAndCondition() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Terms And Conditions"} />

      <TopNav />
      <ResourcesLayout>
        <TermsAndConditions />
      </ResourcesLayout>

      <Footer />
    </>
  );
}
