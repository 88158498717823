import React from "react";
import { Link } from "react-router-dom";
import styles from "./panel.module.css";

export default function WhatsAppButton({children}) {
  return (
    <Link to={"https://wa.me/0614361548"} target="_blank">
      <button
        className={`${styles.waButton} bg-[#25D366] rounded-full text-[#0B133E] font-semibold hover:bg-[#4fC919] transition-transform duration-300 ease-in-out hover:scale-105`}
      >
        {children}
      </button>
    </Link>
  );
}
