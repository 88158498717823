import React from "react";

export default function LoginButton({ children }) {
  return (
    <button className="text-white font-semibold text-md border-2 border-blue-900 p-2 bg-blue-900 rounded-lg flex justify-center md:hover:bg-white md:hover:text-blue-900 md:hover:font-semibold transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg">
      {children}
    </button>
  );
}

export const Button = ({ children, loading = false }) => {
  return (
    <button
      disabled={loading}
      type="submit"
      className="text-white font-semibold text-md border-2 border-blue-900 p-2 bg-blue-900 rounded-lg flex justify-center md:hover:bg-white md:hover:text-blue-900 md:hover:font-semibold transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
    >
      {children}
    </button>
  );
};
