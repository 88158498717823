import React from "react";
import PageHelmet from "../../../../components/pageHelmet";
import AuthPageLayout from "../..";
import RegisterForm from "./registerForm";

export default function Register() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Register Your Account"} />
      <AuthPageLayout>
        <RegisterForm />
      </AuthPageLayout>
    </>
  );
}
