import React from "react";
import Search from "../../../components/search";
import { CreateButton } from "./buttons";
import { Link } from "react-router-dom";
import { BanknotesIcon } from "@heroicons/react/24/outline";

// Mock data for illustration purposes
const summaryData = {
  totalEarnings: 150000,
  earningsToday: 5000,
  earningsThisMonth: 20000,
  earningsThisYear: 180000,
  percentageChangeMonth: 10,
  percentageChangeYear: 5,
};

export default function EarningsSummary() {
  return (
    <div className="text-[#0B133E]">
      <div className="flex items-center justify-between gap-5">
        <Search placeholder={"Search Earnings..."} />
        <Link to={"/earnings/create-earnings"}>
          <CreateButton
            className={"bg-sky-200 hover:bg-sky-300"}
            children={"Enter Earnings"}
            icon={
              <BanknotesIcon className="h-6 w-6" style={{ strokeWidth: 2 }} />
            }
          />
        </Link>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6">
        <h2 className="text-xl font-bold mb-4">Earnings Summary</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {/* Total Earnings */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Total Earnings</h3>
            <p className="text-2xl font-bold">
              R{summaryData.totalEarnings.toLocaleString()}
            </p>
          </div>

          {/* Earnings Today */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold">Today</h3>
            <p className="text-2xl font-bold">
              R{summaryData.earningsToday.toLocaleString()}
            </p>
          </div>

          {/* Earnings This Month */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold">This Month</h3>
            <p className="text-2xl font-bold">
              R{summaryData.earningsThisMonth.toLocaleString()}
            </p>
            <p
              className={`text-sm font-medium ${
                summaryData.percentageChangeMonth >= 0
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {summaryData.percentageChangeMonth >= 0 ? "+" : "-"}
              {Math.abs(summaryData.percentageChangeMonth)}% from last month
            </p>
          </div>

          {/* Earnings This Year */}
          <div className="bg-gray-100 p-4 rounded-lg">
            <h3 className="text-lg font-semibold">This Year</h3>
            <p className="text-2xl font-bold">
              R{summaryData.earningsThisYear.toLocaleString()}
            </p>
            <p
              className={`text-sm font-medium ${
                summaryData.percentageChangeYear >= 0
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {summaryData.percentageChangeYear >= 0 ? "+" : "-"}
              {Math.abs(summaryData.percentageChangeYear)}% from last year
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
