import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

// Mock data for illustration purposes
const data = [
  { month: "Jan", earnings: 12000 },
  { month: "Feb", earnings: 15000 },
  { month: "Mar", earnings: 17000 },
  { month: "Apr", earnings: 14000 },
  { month: "May", earnings: 16000 },
  { month: "Jun", earnings: 18000 },
  { month: "Jul", earnings: 19000 },
  { month: "Aug", earnings: 21000 },
  { month: "Sep", earnings: 22000 },
  { month: "Oct", earnings: 20000 },
  { month: "Nov", earnings: 24000 },
  { month: "Dec", earnings: 25000 },
];

export default function EarningsChart() {
  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-xl font-bold mb-4 text-[#0B133E]">Earnings Trend</h2>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="earnings"
            stroke="#8884d8"
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
