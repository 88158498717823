import React from "react";
import { Link } from "react-router-dom";
import {
  FaWhatsapp,
  FaTelegramPlane,
  FaFacebook,
  FaTiktok,
  FaInstagram,
  FaTwitter,
} from "react-icons/fa";

import { useAppContext } from "../../../context/AppContext";

export default function Credentials() {
  const { appData } = useAppContext();
  return (
    <>
      <div className="text-blue-900 font-semibold">
        <p className="text-center py-1 text-[11px] ">
          <Link to={"/terms-and-conditions"}>
            <span className="md:hover:underline underline-offset-2">
              Terms of use
            </span>
          </Link>{" "}
          &bull;{" "}
          <Link to={"/terms-and-conditions"}>
            <span className="md:hover:underline underline-offset-2">
              Privacy policy
            </span>
          </Link>{" "}
          &bull;{" "}
          <Link to={"https://peacetheecoder.com/"} target="_blank">
            <span className="md:hover:underline underline-offset-2">
              Designed and coded by Peace TheeCoder
            </span>
          </Link>
        </p>

        <div className="flex py-1 items-center justify-center gap-3">
          {appData.socials.whatsapp ? (
            <Link to={`${appData.socials.whatsapp}`} target="_blank">
              <FaWhatsapp
                size={22}
                className="text-[#25D366] hover:text-blue-900"
              />
            </Link>
          ) : null}

          {appData.socials.telegram ? (
            <Link to={`${appData.socials.telegram}`} target="_blank">
              <FaTelegramPlane
                size={23}
                className="text-[#24A1DE] hover:text-blue-900"
              />
            </Link>
          ) : null}

          {appData.socials.facebook ? (
            <Link to={`${appData.socials.facebook}`} target="_blank">
              <FaFacebook
                size={21}
                className="text-[#4267B2] hover:text-blue-900"
              />
            </Link>
          ) : null}

          {appData.socials.tiktok ? (
            <Link to={`${appData.socials.tiktok}`} target="_blank">
              <FaTiktok
                size={20}
                className="text-[#FF0050] hover:text-blue-900"
              />
            </Link>
          ) : null}

          {appData.socials.x ? (
            <Link to={`${appData.socials.x}`} target="_blank">
              <FaTwitter
                size={20}
                className="text-[#24A1DE] hover:text-blue-900"
              />
            </Link>
          ) : null}

          {appData.socials.instagram ? (
            <Link
              to={`${appData.socials.instagram}`}
              target="_blank"
              type="button"
              className="pr-5 md:pr-0"
            >
              <FaInstagram
                size={20}
                className="text-[#D62976] hover:text-blue-900"
              />
            </Link>
          ) : null}
        </div>
      </div>
    </>
  );
}
