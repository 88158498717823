import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AtSymbolIcon,
  UserCircleIcon,
  DevicePhoneMobileIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import { ConfirmationModal, AlertModal } from "../ui/confirmationModals";
import { CreateButton } from "../ui/buttons";

export default function DeleteMentor() {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
  });

  const location = useLocation();
  const navigate = useNavigate();
  const mentor = location.state?.mentor;

  useEffect(() => {
    if (mentor) {
      setFormData({
        fullname: mentor.name || "",
        email: mentor.email || "",
        phone: mentor.phone || "",
      });
    } else {
      navigate("/manage-mentors");
    }
  }, [mentor, navigate]);

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleConfirm = () => {
    setShowConfirmModal(false);
    setShowSuccessMessage(true);

    setTimeout(() => {
      setShowSuccessMessage(false);
      navigate(`/manage-mentors`);
    }, 3000);
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
    setShowSuccessMessage(false);

    navigate("/manage-mentors");
  };

  const handleSubmit = (ev) => {
    if (ev) ev.preventDefault();
    setShowConfirmModal(true);
  };

  return (
    <>
      <h1 className="text-xl font-semibold pt-5 lg:pt-0 text-[#0B133E]">
        <Link to={"/manage-mentors"} className="text-gray-600 font-normal">
          Manage Mentors
        </Link>{" "}
        / Delete Mentor
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="rounded-md bg-gray-50 mt-5 p-4 md:p-6 font-medium text-[#0B133E]">
          <label htmlFor="fullname">Full Name</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.fullname}
              id="fullname"
              name="fullname"
              type="text"
              placeholder="Enter mentor's full name"
              readOnly
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
            />
            <UserCircleIcon className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500 " />
          </div>

          <label htmlFor="email">Email</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.email}
              id="email"
              name="email"
              type="text"
              placeholder="Enter mentor's email"
              readOnly
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
            />
            <AtSymbolIcon className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500" />
          </div>

          <label htmlFor="phone">Phone Number</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.phone}
              id="phone"
              name="phone"
              type="tel"
              placeholder="Enter mentor's phone number"
              readOnly
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
            />
            <DevicePhoneMobileIcon className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500" />
          </div>
        </div>
        <div className="mt-5 mb-10 flex justify-end gap-4">
          <Link to="/manage-mentors">
            <CreateButton
              className={
                "flex flex-row-reverse py-2 bg-sky-200 hover:bg-sky-300"
              }
              children={"Cancel"}
              icon={
                <ArrowLeftCircleIcon
                  className="h-5 w-5"
                  style={{ strokeWidth: 2 }}
                />
              }
            />
          </Link>

          <CreateButton
            className={"py-2 bg-sky-200 hover:bg-sky-300"}
            children={"Confirm Delete"}
            icon={
              <ArrowRightCircleIcon
                className="h-5 w-5"
                style={{ strokeWidth: 2 }}
              />
            }
          />
        </div>
      </form>

      {showConfirmModal && (
        <ConfirmationModal
          children={`Delete ${formData.fullname} as a Mentor?`}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      )}

      {showSuccessMessage && (
        <AlertModal
          children={`Mentor Deleted Successfully!`}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
}
