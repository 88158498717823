import React from "react";
import Search from "../../../components/search";
import { CreateButton } from "../../admin/ui/buttons";
import { Link } from "react-router-dom";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import {
  HiOutlineKey,
  HiOutlineDeviceMobile,
  HiOutlineLightBulb,
  HiOutlineWifi,
} from "react-icons/hi";

export default function HelpSummary() {
  return (
    <div className="text-[#0B133E]">
      <div className="flex items-center justify-between gap-5">
        <Search placeholder={"Search Topics..."} />
        <Link to={"/mentor-help"}>
          <CreateButton
            className={"bg-sky-200 hover:bg-sky-300"}
            children={"Mentor Help"}
            icon={
              <QuestionMarkCircleIcon
                className="h-6 w-6"
                style={{ strokeWidth: 2 }}
              />
            }
          />
        </Link>
      </div>

      <div className="text-[#0B133E]">
        <div className="pt-0">
          <h1 className="text-2xl font-bold pb-5 text-nowrap">
            How To Use Our System
          </h1>
          <span className="font-semibold">Step 1:</span>
          <li className="pl-3 md:pl-5 mb-10">
            <Link
              to={"download"}
              className="text-sky-700 font-medium hover:underline underline-offset-4"
            >
              Download
            </Link>{" "}
            the latest version of RoboTrader EA
          </li>

          <span className="font-semibold">Step 2:</span>
          <li className="pl-3 md:pl-5 mb-10">
            <Link
              to={"download"}
              className="text-sky-700 font-medium hover:underline underline-offset-4"
            >
              Download
            </Link>{" "}
            the latest version of RoboTrader android app
          </li>

          <span className="font-semibold">Step 3:</span>
          <li className="pl-3 md:pl-5 mb-10">
            <Link
              to={"download"}
              className="text-sky-700 font-medium hover:underline underline-offset-4"
            >
              Extract
            </Link>{" "}
            the zip file to find two files: one with a .ex4 extension for MT4
            and one with a .ex5 extension for MT5.
          </li>

          <span className="font-semibold">Step 4:</span>
          <li className="pl-3 md:pl-5">
            Add{" "}
            <Link
              to={"download"}
              className="text-sky-700 font-medium hover:underline underline-offset-4"
            >
              `https://robotrader.take-profit-signals.co.za/`
            </Link>{" "}
            to the URL list in your MT4 or MT5.{" "}
          </li>
        </div>

        <div className="py-10">
          <h2 className="font-semibold text-xl pb-3">Before You Begin</h2>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-3">
              <HiOutlineKey className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
              <p className="">
                Ensure you have a license key from your mentor to access all
                system features.
              </p>
            </div>

            <div className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-3">
              <HiOutlineWifi className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
              <p>
                Ensure your phone remains connected at all times, through wifi
                or cellular network.
              </p>
            </div>

            <div className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-3">
              <HiOutlineDeviceMobile className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
              <p>
                Ensure your device is in good working condition and running
                Android 7.1 or higher.
              </p>
            </div>

            <div className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-3">
              <HiOutlineLightBulb className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
              <p>
                Ensure your phone remains unlocked and has the drop-down feature
                enabled.
              </p>
            </div>
          </div>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">FAQs...</h2>
          <ul className="list-disc pl-5  flex flex-col gap-3">
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et,
              perferendis.
            </li>

            <li>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
              distinctio!
            </li>
            <li>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Incidunt, iste!
            </li>
          </ul>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">Get in Touch</h2>
          <Link
            to={"https://wa.me/0614361548"}
            target="_blank"
            className="font-semibold text-sky-800 hover:underline underline-offset-4"
          >
            {" "}
            <em>WhatsApp at +27 61 436 1548</em>
          </Link>{" "}
        </div>
      </div>
    </div>
  );
}
