import React from "react";
import logo from "../../../assets/img/icons8-logo-100.png";
import WhatsAppButton from "./whatsAppButton";
import profilePicture from "../../../assets/img/icons8-profile-picture-100.png";
import { NavLink, Link, useParams } from "react-router-dom";
import "../index.css";
import styles from "./panel.module.css";
import { HamburgerNav } from "./hamburgerNav";
import {
  FaHome,
  FaInfo,
  FaTasks,
  FaDownload,
  FaUser,
  FaWhatsapp,
} from "react-icons/fa";

export const topNavLinks = [
  { name: "Home", href: "/", icon: <FaHome /> },
  { name: "About Us", href: "/about-us", icon: <FaInfo /> },
  {
    name: "How to use our system",
    href: "/how-to-use-system",
    icon: <FaUser />,
  },
  {
    name: "Terms & Conditions",
    href: "/terms-and-conditions",
    icon: <FaTasks />,
  },
  { name: "Downloads", href: "/downloads", icon: <FaDownload /> },
];

export default function TopNav() {
  const { appname } = useParams();

  return (
    <header className="bg-[#0B133E] md:h-[125px] p-5 md:p-10 fixed top-0 w-full z-50">
      <div className="flex items-center justify-between">
        <HamburgerNav />

        <Link to={`/${appname}/`} className="items-center gap-2 hidden lg:flex">
          <img
            src={logo}
            alt="Company Logo"
            className="hover:cursor-pointer w-[40px] bg-white rounded-[50%]"
          />
          <h2 className="hidden lg:block text-sky-100 text-xl font-semibold leading-none">
            Robotrader
          </h2>
        </Link>

        <nav className="hidden lg:flex">
          <ul className={`${styles.topNav} flex items-center justify-center`}>
            {topNavLinks.map((topLink) => (
              <li key={topLink.name}>
                <NavLink
                  to={`/${appname}${topLink.href}`}
                  className={({ isActive }) =>
                    `relative text-sky-100 font-normal flex items-center after:absolute after:left-0 after:top-[23px] after:w-full after:h-[2px] after:bg-sky-100 after:transition-transform after:duration-300 after:ease-in-out after:transform ${
                      isActive
                        ? "after:scale-x-100"
                        : "hover:after:scale-x-100 after:scale-x-0"
                    }`
                  }
                >
                  {topLink.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>

        <div className="flex items-center gap-[13px] lg:gap-4">
          <div className="hidden lg:block">
            <WhatsAppButton>WhatsApp Us</WhatsAppButton>
          </div>

          <div className="block lg:hidden text-[#25D366]">
            <WhatsAppButton>
              <FaWhatsapp size={25} />
            </WhatsAppButton>
          </div>

          <Link to={`./profile`}>
            <img
              src={profilePicture}
              alt="Profile"
              className="w-[40px] bg-white rounded-[50%]"
            />
          </Link>
        </div>
      </div>
    </header>
  );
}
