import { useEffect, useState } from "react";
import Credentials from "../../components/credentials";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Button from "../../components/buttons";
import PageHelmet from "../../../../components/pageHelmet";
import AuthPageLayout from "../..";
import { ButtonLoader } from "../../../../components/buttonLoader";
import { useAppContext } from "../../../../context/AppContext";

export default function EmailVerification() {
  const { appData } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!location.state || !location.state.otp || !location.state.data)
      navigate(`/${appData.name}/register/`);
  }, [appData.name, location.state, navigate]);

  const sentOtp = location.state?.otp;
  const sentData = location.state?.data;

  console.log(sentOtp);
  // console.log(sentData);

  const [otp, setOtp] = useState(["", "", "", "", ""]);
  const [errors, setErrors] = useState({});

  const handleChange = (ev, index) => {
    const { value } = ev.target;

    if (isNaN(value) || value.length > 1) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (errors.codeErr) setErrors({});

    if (value && index < otp.length - 1) {
      ev.target.nextSibling.focus();
    } else if (value === "" && index > 0) {
      ev.target.previousSibling?.focus();
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    const otpCode = otp.join("");

    if (!/^\d{5}$/.test(otpCode)) {
      setIsLoading(false);
      setErrorMessage("Verification code must be five digits!"); // Changed from null to a string
      setErrors({ codeErr: "Verification code must be five digits!" });
      return;
    }

    if (otpCode === sentOtp) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/authentication/register/`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(sentData),
          }
        );

        const data = await response.json();
        setIsLoading(false);

        if (data.status !== "success") {
          setErrorMessage(data.message);
        } else {
          const id = data.data.id;
          setErrorMessage("");
          navigate(`/${appData.name}/payment`, { state: { id: id } });
        }
      } catch (err) {
        setIsLoading(false);
        setErrorMessage("Unknown Error! Try Again.");
      }
    } else {
      setIsLoading(false);
      setErrorMessage("Incorrect OTP.");
    }
  };

  return (
    <>
      <PageHelmet
        pageTitle={`${appData.name} - Enter Your Verification Code`}
      />
      <AuthPageLayout>
        <form onSubmit={handleSubmit}>
          <Link to={`/${appData.name}/register`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-8 text-blue-900 absolute left-7 top-5 md:left-[43%] lg:left-[52%] svg-icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
              />
            </svg>
          </Link>

          <div className="flex flex-col items-center justify-center min-h-[100dvh]">
            <div className="flex flex-col items-center pb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="currentColor"
                className="size-[71.6%] text-blue-900"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>

              <h1 className="text-center text-xl font-normal">
                Verify your email
              </h1>
            </div>

            <p className="text-center text-[10px] py-[6.6px] lg:px-[151px]">
              Please enter the One Time Pin sent to the email address associated
              with your account. Once the OTP expires, click on the Resend Code
              link provided below, you will receive a new OTP code.
            </p>

            {!isLoading && errorMessage ? (
              <div
                id="error_msg"
                className="bg-red-700 text-red-100 px-3 font-semibold rounded-lg my-2 "
              >
                {errorMessage}
              </div>
            ) : (
              ""
            )}

            <div className="flex gap-1 my-[10px] flex-col w-[300px] md:w-[330px] lg:w-[360px]">
              <div className="flex flex-col gap-">
                <div className="relative h-full w-full">
                  <div className="hidden absolute bg-blue-900 h-[41px] w-[37px] top-0 left-0 rounded-l-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="hidden w-6 h-6 absolute left-2 top-[10px] text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>
                </div>

                <div className="flex gap-1">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={value}
                      onChange={(ev) => handleChange(ev, index)}
                      onFocus={(ev) => ev.target.select()}
                      className="border border-blue-900 w-full p-2 pl-10 rounded-lg mb-1 focus:outline-none focus:ring-1 focus:ring-blue-600"
                    />
                  ))}
                </div>
                {errors.codeErr && (
                  <span className="error-message">{errors.codeErr}</span>
                )}
              </div>

              <Button children={isLoading ? <ButtonLoader /> : "Verify"} />

              <div className="mt-[10px]">
                <div className="flex flex-col">
                  <p className="text-center text-[12px]">
                    Having trouble receiving the OTP?{" "}
                    <Link to={"/resend-otp"}>
                      <span className="text-blue-900 font-semibold md:hover:underline underline-offset-2">
                        Resend Code
                      </span>
                    </Link>
                  </p>
                </div>
                <Credentials />
              </div>
            </div>
          </div>
        </form>
      </AuthPageLayout>
    </>
  );
}
