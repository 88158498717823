import React from "react";
import "./loader.css";

export const Loader = ({ className, children }) => {
  return (
    <div className={`${className}`}>
      <div className={`loader`}></div>
      <p className="font-medium">{children}</p>
    </div>
  );
};
