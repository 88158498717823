import React, { useState } from "react";
import { CreateButton } from "./buttons";
import { ArrowUpOnSquareIcon } from "@heroicons/react/24/outline";

export default function ManageUploads() {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  // Handle file selection
  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  // Handle file upload
  const handleFileUpload = async () => {
    // Convert FileList to Array
    const filesArray = Array.from(selectedFiles);

    // Simulate file upload
    const uploadedFilesList = filesArray.map((file) => ({
      name: file.name,
      size: file.size,
      type: file.type,
      // Simulate URL for display purpose
      url: URL.createObjectURL(file),
    }));

    setUploadedFiles(uploadedFilesList);
    // Reset selected files
    setSelectedFiles([]);
  };

  return (
    <>
      <div className="mt-5 flex items-center justify-between gap-5">
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="p-0.5 font-medium rounded-md border border-gray-400 w-full"
        />
        <CreateButton
          children={"Upload"}
          onClick={handleFileUpload}
          className={"bg-sky-200 hover:bg-sky-300"}
          icon={
            <ArrowUpOnSquareIcon
              className="h-6 w-6"
              style={{ strokeWidth: 2 }}
            />
          }
        />
      </div>

      <div className="mt-8">
        {uploadedFiles.length > 0 && (
          <div>
            <h2 className="text-lg font-semibold text-[#0B133E]">
              Uploaded Files:
            </h2>
            <ul className="list-disc pl-5 mt-2">
              {uploadedFiles.map((file, index) => (
                <li key={index} className="mb-2">
                  <a
                    href={file.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    {file.name} ({(file.size / 1024).toFixed(2)} KB)
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
}
