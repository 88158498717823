import React from "react";
import {
  CreditCardIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

export default function PurchaseKeys() {
  return (
    <>
      <div className="text-xl mt-5 font-semibold lg:pt-0 text-[#0B133E] lg:mt-0">
        <Link to={"/manage-keys"} className="text-gray-600 font-normal">
          Manage Keys
        </Link>{" "}
        / Purchase Keys
      </div>{" "}
      <div className="flex flex-col lg:flex-row lg:mt-5 lg:border lg:border-t-sky-300">
        <div className="mt-5 font-medium text-[#0B133E] lg:flex lg:flex-col lg:mt-[55px]">
          <div className="border border-t-sky-300 lg:border-0">
            <p className="font-semibold mt-3">
              Robotrader License Keys are sold for
            </p>
            <p>$10 per license key</p>
          </div>

          <div className="mt-10 mb-10">
            <p>
              <em>
                <strong>Please Note</strong>
              </em>
              , if you purchase <strong>50 or more</strong> license keys, you
              will be charged <strong>$7</strong> per license key!
            </p>
          </div>
          <div className="flex md:justify-end lg:mt-5 lg:mr-5">
            <CreditCardIcon className="h-6 w-6" />
            <CreditCardIcon className="h-6 w-6" />
            <CreditCardIcon className="h-6 w-6" />
            <CreditCardIcon className="h-6 w-6" />
          </div>
        </div>
        <form onSubmit={""}>
          <div className="rounded-md bg-white mt-5 p-4 md:p-6 font-medium text-[#0B133E] lg:mt-10">
            <h2 className="text-center font-semibold text-xl">
              Select Payment method
            </h2>

            <div className="my-5 md:flex justify-between">
              <div className="pt-3 text-[15px]">
                <div className="max-w-full gap-2 flex items-center bg-gray-200 py-3 rounded-md text-gray-500 md:w-[622px] lg:w-[444px]">
                  <CreditCardIcon
                    className="h-6 w-6 ml-2"
                    style={{ strokeWidth: 2 }}
                  />
                  <select
                    name="method"
                    id="method"
                    className="p-0.5 rounded-md"
                  >
                    <option value="select-method">Payment Method</option>
                    <option value="paypal">PayPal</option>
                    <option value="credit-card">Credit Card</option>
                  </select>
                </div>
              </div>
            </div>

            <label htmlFor="quantity">Quantity</label>
            <div className="relative rounded-md">
              <input
                id="quantity"
                name="quantity"
                type="number"
                className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-3 text-sm outline-2 placeholder:text-gray-500"
                placeholder="10"
              />
            </div>
          </div>
          <div className="mb-10 mt-5 flex justify-end gap-5">
            <Link to="/manage-keys">
              <button className="flex bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300">
                <ArrowLeftCircleIcon
                  className="h-6 w-6"
                  style={{ strokeWidth: 2 }}
                />
                Cancel
              </button>
            </Link>
            <button className="flex flex-row-reverse bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300">
              <ArrowRightCircleIcon
                className="h-6 w-6"
                style={{ strokeWidth: 2 }}
              />
              Purchase
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
