
import React, { createContext, useState, useContext } from 'react';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  const [appData, setAppData] = useState({});
  const [userAccessData, setUserAccessData] = useState({});


  return (
    <AppContext.Provider value={{ appData, setAppData,  userAccessData, setUserAccessData }}>
      {children}
    </AppContext.Provider>
  );
};
