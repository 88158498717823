import React, { useState } from "react";

export default function Settings() {
  const [theme, setTheme] = useState("light");

  const handleThemeChange = (event) => {
    setTheme(event.target.value);
  };

  const handleSave = () => {
    setSetting("");
    // Save logic here
    console.log("Theme selected:", theme);
  };
  const [notifications, setNotifications] = useState({
    emailNotifications: true,
    smsNotifications: false,
  });

  const handleToggle = (event) => {
    const { name, checked } = event.target;
    setNotifications({ ...notifications, [name]: checked });
  };

  // const handleSave2 = () => {
  //   // Save logic here
  //   console.log("Notification settings saved:", notifications);
  // };

  const [profile, setProfile] = useState({ name: "", email: "" });

  const handleChange = (event) => {
    setProfile({ ...profile, [event.target.name]: event.target.value });
  };

  const [setting, setSetting] = useState("");

  // const handleChange2 = (event) => {
  //   setSetting(event.target.value);
  // };

  return (
    <div className="text-[#0B133E]">
      <h2 className="text-xl mt-5 font-semibold lg:mt-0">Manage Settings</h2>
      <div className="mt-5 border border-sky-400"></div>

      <div className="md:flex md:justify-between items-center">
        <div className="mt-5">
          <h3 className="text-lg font-medium">Theme Selection</h3>
          <form>
            <label className="block mb-2">
              Select Theme:
              <select
                value={theme}
                onChange={handleThemeChange}
                className="border rounded px-2 py-1 ml-2"
              >
                <option value="light">Light</option>
                <option value="dark">Dark</option>
              </select>
            </label>
            <button
              type="button"
              onClick={handleSave}
              className="mt-2 bg-sky-200 text-[#0B133E] font-semibold px-4 py-2 rounded"
            >
              Save Theme
            </button>
          </form>
        </div>
        <div className="mt-10 border border-sky-400 md:hidden"></div>

        <div className="mt-5">
          <h3 className="text-lg font-medium">Notification Preferences</h3>
          <form>
            <label className="block mb-2 ml-3">
              <input
                type="checkbox"
                name="emailNotifications"
                checked={notifications.emailNotifications}
                onChange={handleToggle}
                className="mr-2"
              />
              Email Notifications
            </label>
            <label className="block mb-2 ml-3">
              <input
                type="checkbox"
                name="smsNotifications"
                checked={notifications.smsNotifications}
                onChange={handleToggle}
                className="mr-2"
              />
              SMS Notifications
            </label>
            <button
              type="button"
              onClick={handleSave}
              className="mt-2 bg-sky-200 text-[#0B133E] font-semibold px-4 py-2 rounded"
            >
              Save Preferences
            </button>
          </form>
        </div>
      </div>

      <div className="mt-10 border border-sky-400"></div>

      <div className="mt-5">
        <h3 className="text-lg font-medium">Profile Information</h3>
        <form>
          <label className="flex flex-col mb-2">
            Name:
            <input
              type="text"
              name="name"
              value={profile.name}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          </label>
          <label className="flex flex-col mb-2">
            Email:
            <input
              type="email"
              name="email"
              value={profile.email}
              onChange={handleChange}
              className="border rounded px-2 py-1"
            />
          </label>

          <button
            type="button"
            onClick={handleSave}
            className="mt-2 bg-sky-200 text-[#0B133E] font-semibold px-4 py-2 rounded"
          >
            Save Changes
          </button>
        </form>
      </div>

      <div className="mt-10 border border-sky-400"></div>

      <form className="mt-5">
        <label className="flex flex-col mb-2">
          Example Setting:
          <input
            type="text"
            value={setting}
            onChange={handleChange}
            className="border rounded px-2 py-1"
          />
        </label>
        <button
          type="button"
          onClick={handleSave}
          className="mt-2 bg-sky-200 text-[#0B133E] font-semibold px-4 py-2 rounded"
        >
          Save Settings
        </button>
      </form>
    </div>
  );
}
