import React, { useState } from "react";
import { CreateButton } from "../../admin/ui/buttons";
import Search from "../../../components/search";
import { Link } from "react-router-dom";
import {
  BoltIcon,
  PlusIcon,
  ShoppingCartIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  UserCircleIcon,
  DevicePhoneMobileIcon,
  ChartPieIcon,
  KeyIcon,
} from "@heroicons/react/24/outline";

export default function KeysSummary() {
  const [showNewKeyForm, setShowNewKeyForm] = useState(false);
  const [showActKeyForm, setShowActKeyForm] = useState(false);

  const handleNewKeyClick = () => {
    setShowNewKeyForm(true);
    setShowActKeyForm(false);
  };

  const handleActKeyClick = () => {
    setShowActKeyForm(true);
    setShowNewKeyForm(false);
  };

  const handleCancel = () => {
    setShowNewKeyForm(false);
    setShowActKeyForm(false);
  };

  return (
    <div className="text-[#0B133E]">
      <div className="flex items-center justify-between gap-5">
        <Search placeholder={"Search Keys..."} />
        <Link to={"/manage-keys/purchase-keys"}>
          <CreateButton
            className={"bg-sky-200 hover:bg-sky-300"}
            children={"Purchase Keys"}
            icon={
              <ShoppingCartIcon
                className="h-6 w-6"
                style={{ strokeWidth: 2 }}
              />
            }
          />
        </Link>
      </div>

      {!showNewKeyForm && !showActKeyForm && (
        <div className="flex gap-5">
          <CreateButton
            className={"bg-sky-200 hover:bg-sky-300"}
            children={"New Key"}
            icon={<PlusIcon className="h-6 w-6" style={{ strokeWidth: 2 }} />}
            onClick={handleNewKeyClick}
          />

          <CreateButton
            className={"bg-sky-200 hover:bg-sky-300"}
            children={"Reactivate Key"}
            icon={<BoltIcon className="h-6 w-6" style={{ strokeWidth: 2 }} />}
            onClick={handleActKeyClick}
          />
        </div>
      )}

      {showNewKeyForm && (
        <form
          onSubmit={() => {
            /* handle form submission */
          }}
        >
          <div className="rounded-md bg-white p-4 md:p-6 pt-10 font-medium text-[#0B133E]">
            <div className="flex flex-col gap-1">
              <h2 className="font-semibold text-xl">Generate License Key</h2>
              <p className="text-gray-400">
                Enter details of a user to be authorized
              </p>
            </div>

            <div className="mt-10">
              <label htmlFor="fullName">Full Name</label>
              <div className="relative rounded-md">
                <input
                  id="ea-name"
                  name="ea-name"
                  type="text"
                  placeholder="Enter full name"
                  className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
                />
                <UserCircleIcon
                  className="absolute left-2.5 top-1.5 h-6 w-6 text-gray-500 "
                  style={{ strokeWidth: 2 }}
                />
              </div>

              <div className="my-5 md:flex justify-between">
                <div className="pt-3 text-[15px]">
                  <div className="max-w-full gap-2 flex items-center bg-gray-200 py-3 rounded-md text-gray-500 md:w-[622px] lg:w-[850px]">
                    <BoltIcon
                      className="h-6 w-6 ml-2"
                      style={{ strokeWidth: 2 }}
                    />
                    <select
                      name="method"
                      id="method"
                      className="p-1 rounded-md"
                    >
                      <option value="select-method">Select EA</option>
                      <option value="ea-1">EA 1</option>
                      <option value="ea-2">EA 2</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="my-5 md:flex justify-between">
                <div className="pt-3 text-[15px]">
                  <div className="max-w-full gap-2 flex items-center bg-gray-200 py-3 rounded-md text-gray-500 md:w-[622px] lg:w-[850px]">
                    <DevicePhoneMobileIcon
                      className="h-6 w-6 ml-2"
                      style={{ strokeWidth: 2 }}
                    />
                    <select
                      name="method"
                      id="method"
                      className="p-1 rounded-md"
                    >
                      <option value="select-method">Select App</option>
                      <option value="robotrader">RoboTrader</option>
                      <option value="smartpro">SmartProEA</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="my-5 md:flex justify-between">
                <div className="pt-3 text-[15px]">
                  <div className="max-w-full gap-2 flex items-center bg-gray-200 py-3 rounded-md text-gray-500 md:w-[622px] lg:w-[850px]">
                    <ChartPieIcon
                      className="h-6 w-6 ml-2"
                      style={{ strokeWidth: 2 }}
                    />
                    <select
                      name="method"
                      id="method"
                      className="p-1 rounded-md"
                    >
                      <option value="select-method">Select Plan</option>
                      <option value="3-day">3 Days</option>
                      <option value="5-day">5 Days</option>
                      <option value="30-day">30 Days</option>
                      <option value="90-day">90 Days</option>
                      <option value="180-day">180 Days</option>
                      <option value="365-day">365 Days</option>
                      <option value="lifetime">Life-time</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <p className="text-red-500 text-[12px] font-semibold">
              Please note: this key will only work on one device, if your users
              loose it or if they change their phone, they must purchase a new
              key
            </p>
            <p className="pt-2">
              We removed the delete feature because mentors were abusing it
            </p>
            <div className="mt-[10px] mb-3">
              <label className="flex items-center gap-1">
                <input name="checkbox" type="checkbox" className="" />
                <p className=" text-md">I agree to these terms</p>
              </label>
            </div>
          </div>
          <div className="mb-10 mt-5 flex justify-end gap-5">
            <button
              type="button"
              onClick={handleCancel}
              className="flex bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300"
            >
              <ArrowLeftCircleIcon
                className="h-6 w-6"
                style={{ strokeWidth: 2 }}
              />
              Cancel
            </button>
            <button className="flex flex-row-reverse bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300">
              <ArrowRightCircleIcon
                className="h-6 w-6"
                style={{ strokeWidth: 2 }}
              />
              Generate
            </button>
          </div>
        </form>
      )}

      {showActKeyForm && (
        <form
          onSubmit={() => {
            /* handle form submission */
          }}
        >
          <div className="rounded-md bg-white p-4 md:p-6 pt-10 font-medium text-[#0B133E]">
            <div className="flex flex-col gap-1">
              <h2 className="font-semibold text-xl">
                Reactivate a License Key
              </h2>
              <p className="text-gray-400">
                Enter license key you wish to reactivate here
              </p>
            </div>

            <div className="mt-10">
              <label htmlFor="reactivate-key">Reactivate Key</label>
              <div className="relative rounded-md">
                <input
                  id="reactivate-key"
                  name="reactivate-key"
                  type="text"
                  placeholder="Enter License Key"
                  className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
                />
                <KeyIcon
                  className="absolute left-2.5 top-1.5 h-6 w-6 text-gray-500 "
                  style={{ strokeWidth: 2 }}
                />
              </div>
            </div>
            <div className="mt-[20px] mb-3">
              <label className="flex items-center gap-1">
                <input name="checkbox" type="checkbox" className="" />
                <p className=" text-md">I agree to these terms</p>
              </label>
            </div>
          </div>
          <div className="mb-10 mt-5 flex justify-end gap-5">
            <button
              type="button"
              onClick={handleCancel}
              className="flex bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300"
            >
              <ArrowLeftCircleIcon
                className="h-6 w-6"
                style={{ strokeWidth: 2 }}
              />
              Cancel
            </button>
            <button className="flex flex-row-reverse bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300">
              <ArrowRightCircleIcon
                className="h-6 w-6"
                style={{ strokeWidth: 2 }}
              />
              Reactivate
            </button>
          </div>
        </form>
      )}
    </div>
  );
}
