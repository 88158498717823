import React from "react";
import { Link } from "react-router-dom";
import {
  ArrowRightCircleIcon,
  ArrowLeftCircleIcon,
  PhotoIcon,
  SwatchIcon,
  CheckBadgeIcon,
  ClipboardDocumentIcon,
} from "@heroicons/react/24/outline";

export default function EditEA() {
  return (
    <>
      <div className="text-xl mt-5 font-semibold lg:pt-0 text-[#0B133E] lg:mt-0">
        <Link to={"/manage-eas"} className="text-gray-600 font-normal">
          Manage EAs
        </Link>{" "}
        <Link
          to={"/manage-eas/ea-details"}
          className="text-gray-600 font-normal"
        >
          / EA Details
        </Link>{" "}
        / Edit EA
      </div>{" "}
      <form
        onSubmit={() => {
          /* handle form submission */
        }}
      >
        <div className="rounded-md bg-white p-4 md:p-6 pt-10 mt-5 font-medium text-[#0B133E]">
          <div className="flex flex-col gap-1">
            <h2 className="font-semibold text-xl">Edit EA</h2>
            <p className="text-gray-400">123456</p>
          </div>

          <div className="mt-5">
            <label htmlFor="image-url">Image URL</label>
            <div className="relative rounded-md">
              <input
                id="image-url"
                name="image-url"
                type="text"
                placeholder="Enter full name"
                className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
              />
              <PhotoIcon className="absolute left-2.5 top-1.5 h-6 w-6 text-gray-500 " />
            </div>

            <div className="mb-3 md:flex justify-between">
              <div className="pt-3 text-[15px]">
                <div className="max-w-full gap-2 flex items-center bg-gray-200 py-3 rounded-md text-gray-500 md:w-[622px] lg:w-[850px]">
                  <SwatchIcon className="h-6 w-6 ml-2" />
                  <select name="method" id="method" className="p-1 rounded-md">
                    <option value="select-method">Select Color</option>
                    <option value="color-1">Color 1</option>
                    <option value="color-1">Color 2</option>
                    <option value="color-1">Color 3</option>
                    <option value="color-1">Color 4</option>
                    <option value="color-1">Color 5</option>
                    <option value="color-1">Color 6</option>
                    <option value="color-1">Color 7</option>
                    <option value="color-1">Color 8</option>
                    <option value="color-1">Color 9</option>
                    <option value="color-1">Color 10</option>
                  </select>
                </div>
              </div>
            </div>
            <label htmlFor="ea-description">EA Description</label>
            <div className="relative rounded-md">
              <input
                id="ea-description"
                name="ea-description"
                type="text"
                placeholder="Enter EA Description"
                className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
              />
              <ClipboardDocumentIcon className="absolute left-2.5 top-1.5 h-6 w-6 text-gray-500 " />
            </div>

            <label htmlFor="media-url">Social Media Group URL</label>
            <div className="relative rounded-md">
              <input
                id="media-url"
                name="media-url"
                type="text"
                placeholder="Enter Social Media Group URL"
                className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
              />
              <CheckBadgeIcon className="absolute left-2.5 top-1.5 h-6 w-6 text-gray-500 " />
            </div>
          </div>
        </div>
        <div className="mb-10 mt-5 flex justify-end gap-5">
          <button
            type="button"
            // onClick={handleCancel}
            className="flex bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300"
          >
            <ArrowLeftCircleIcon className="h-6 w-6" />
            Cancel
          </button>
          <button className="flex flex-row-reverse bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300">
            <ArrowRightCircleIcon className="h-6 w-6" />
            Save
          </button>
        </div>
      </form>
    </>
  );
}
