import React from "react";
import PageHelmet from "../../../../components/pageHelmet";
import TopNav from "../../components/topNav";
import Footer from "../../components/footer";
import ResourcesLayout from "../../components/resourcesLayout";
import { AboutUsMain } from "./aboutUsMain";

export default function AboutUs() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - About Us"} />

      <TopNav />
      <ResourcesLayout>
        <AboutUsMain />
      </ResourcesLayout>

      <Footer />
    </>
  );
}
