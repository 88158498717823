import React from "react";
import { Link } from "react-router-dom";
import { CreateButton } from "../../admin/ui/buttons";
import {
  BookmarkIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { Outlet, useLocation } from "react-router-dom";

export default function EADetails() {
  const location = useLocation();
  const isNestedRoute = location.pathname.includes("/manage-eas/ea-details/");
  return (
    <>
      {isNestedRoute ? (
        <Outlet />
      ) : (
        <>
          <div className="text-xl mt-5 font-semibold lg:pt-0 text-[#0B133E] lg:mt-0">
            <Link to={"/manage-eas"} className="text-gray-600 font-normal">
              Manage EAs
            </Link>{" "}
            / EA Details
          </div>{" "}
          <div className="flex flex-col rounded-md bg-white mt-5 p-4 md:p-6 font-medium text-[#0B133E] lg:flex-row lg:justify-between">
            <div className="flex flex-col justify-between">
              <div>
                <h2 className="font-semibold text-xl">EA code goes here...</h2>

                <p className="pt-5">
                  EA full name plus version number goes here...
                </p>
              </div>

              <p className="text-[13px] text-red-600 font-semibold py-5">
                This is the ea code for ea.name. Please <br /> do not share this
                code with anyone else.
              </p>
            </div>

            <div className="flex flex-col justify-between">
              <div className="lg:leading-9 pb-7">
                <p>
                  <Link className="text-sky-400 hover:underline underline-offset-4 ">
                    Download
                  </Link>{" "}
                  MT4 & MT5 Files
                </p>
                <p>
                  <Link className="text-sky-400 hover:underline underline-offset-4 ">
                    Download
                  </Link>{" "}
                  Our App
                </p>
                <p>
                  <Link className="text-sky-400 hover:underline underline-offset-4 ">
                    Click Here
                  </Link>{" "}
                  Learn how to use our system
                </p>
              </div>

              <div className="flex justify-end md:justify-start lg:justify-end gap-5">
                <Link to={"/manage-eas/ea-details/edit-ea"}>
                  <CreateButton
                    children={"Edit"}
                    className="bg-green-600 text-gray-200 hover:bg-green-700 flex-row-reverse py-2"
                    icon={
                      <PencilIcon
                        className="h-5 w-5"
                        style={{ strokeWidth: 2 }}
                      />
                    }
                  />
                </Link>

                <CreateButton
                  children={"Delete"}
                  className="bg-red-600 text-gray-200 hover:bg-red-700 flex-row-reverse py-2"
                  icon={
                    <TrashIcon className="h-5 w-5" style={{ strokeWidth: 2 }} />
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex-col rounded-md bg-white mt-5 p-4 md:p-6 font-medium text-[#0B133E] lg:flex-row lg:justify-between mb-10">
            <div className="">
              <h2 className="font-semibold text-xl pb-5">Symbols</h2>
              <p className="pb-2">Total symbols for this.username</p>
              <form action="">
                <label htmlFor="" className="hidden">
                  Symbols
                </label>
                <div className="flex gap-5">
                  <input
                    type="text"
                    className="p-2 border border-gray-400 pl-5 rounded-md w-full lg:w-60"
                    placeholder="Symbol Name..."
                  />
                  <CreateButton
                    children={"Save"}
                    className="bg-yellow-500 font-semibold hover:bg-yellow-600 flex-row-reverse"
                    icon={
                      <BookmarkIcon
                        className="h-6 w-6 md:w-5"
                        style={{ strokeWidth: 2 }}
                      />
                    }
                  />
                </div>
              </form>
            </div>

            <div className="">
              <h2 className="font-semibold text-xl pt-20">Symbols Overview</h2>
              {/* Append overviews here... */}
            </div>
          </div>
        </>
      )}
    </>
  );
}
