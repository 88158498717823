import React, { useState } from "react";
import { Link } from "react-router-dom";
import { CreateButton } from "../../admin/ui/buttons";
import profilePhoto from "../../../../../assets/img/logo.jpeg";
import {
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  CalendarDateRangeIcon,
  EnvelopeIcon,
  MapIcon,
  MapPinIcon,
  UserGroupIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import { BsGenderAmbiguous } from "react-icons/bs";

export default function EditProfile() {
  const [photo, setPhoto] = useState(profilePhoto);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
  };

  return (
    <>
      <h1 className="text-xl font-semibold text-[#0B133E] mt-5 lg:mt-0">
        Edit Profile
      </h1>
      <div className="lg:flex justify-between">
        <form onSubmit={handleSubmit} className="lg:w-[50%]">
          <div className="rounded-md bg-gray-50 mt-5 p-4 md:p-6 font-medium text-[#0B133E]">
            <label htmlFor="fullname">Full Name</label>
            <input
              id="fullname"
              name="fullname"
              type="text"
              placeholder="Enter mentor's full name"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-8 text-sm outline-2"
            />
            <UserIcon
              className="h-5 w-5 absolute top-[276px] left-[43px] md:top-[330px] md:left-[72px] lg:top-[260px] lg:left-[444px]"
              style={{ strokeWidth: 2 }}
            />

            <label htmlFor="dob">Date of Birth</label>
            <input
              id="dob"
              name="dob"
              type="date"
              className="w-full rounded-md border border-gray-200 py-2 mb-2 pl-8 text-sm"
            />
            <CalendarDateRangeIcon
              className="h-5 w-5 absolute top-[350px] left-[43px] md:top-[402px] md:left-[72px] lg:top-[332px] lg:left-[444px]"
              style={{ strokeWidth: 2 }}
            />

            <label htmlFor="gender">Gender</label>
            <select
              id="gender"
              name="gender"
              className="w-full rounded-md border border-gray-200 py-2 mb-2 pl-8 text-sm"
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <BsGenderAmbiguous
              className="h-5 w-5 absolute top-[420px] left-[43px] md:top-[472px] md:left-[72px] lg:top-[402px] lg:left-[444px]"
              style={{ strokeWidth: 0.5 }}
            />

            <label htmlFor="role">Role</label>
            <select
              id="role"
              name="role"
              className="w-full rounded-md border border-gray-200 py-2 mb-2 pl-8 text-sm"
            >
              <option value="">Select role</option>
              <option value="admin">Admin</option>
              <option value="mentor">Mentor</option>
              <option value="user">User</option>
            </select>
            <UserGroupIcon
              className="h-5 w-5 absolute top-[488px] left-[43px] md:top-[542px] md:left-[72px] lg:top-[472px] lg:left-[444px]"
              style={{ strokeWidth: 2 }}
            />

            <label htmlFor="email">Email</label>
            <input
              id="email"
              name="email"
              type="email"
              placeholder="Enter mentor's email"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-8 text-sm outline-2"
            />
            <EnvelopeIcon
              className="h-5 w-5 absolute top-[559px] left-[43px] md:top-[612px] md:left-[72px] lg:top-[542px] lg:left-[444px]"
              style={{ strokeWidth: 2 }}
            />

            <label htmlFor="region">Region</label>
            <input
              id="region"
              name="region"
              type="text"
              placeholder="Enter region"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-8 text-sm outline-2"
            />
            <MapPinIcon
              className="h-5 w-5 absolute top-[628px] left-[43px] md:top-[682px] md:left-[72px] lg:top-[612px] lg:left-[444px]"
              style={{ strokeWidth: 2 }}
            />

            <label htmlFor="address">Address</label>
            <input
              id="address"
              name="address"
              type="text"
              placeholder="Enter address"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-8 text-sm outline-2"
            />
            <MapIcon
              className="h-5 w-5 absolute top-[699px] left-[43px] md:top-[752px] md:left-[72px] lg:top-[682px] lg:left-[444px]"
              style={{ strokeWidth: 2 }}
            />
          </div>
        </form>
        <div className="mt-10 flex flex-col items-center mb-10">
          <p className="mb-2">Change Photo:</p>
          <img src={photo} alt="Profile" className="w-60 h-60 rounded-full" />
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="mt-4"
          />
        </div>
      </div>
      <div className="mt-5 flex justify-end gap-5">
        <Link to="/profile">
          <CreateButton
            className="flex flex-row-reverse py-3 bg-sky-200 hover:bg-sky-300"
            children={"Cancel"}
            icon={
              <ArrowLeftCircleIcon
                className="w-5 h-5"
                style={{ strokeWidth: 2 }}
              />
            }
          />
        </Link>

        <CreateButton
          className={"bg-sky-200 hover:bg-sky-300"}
          children={"Save"}
          icon={
            <ArrowRightCircleIcon
              className="h-5 w-5"
              style={{ strokeWidth: 2 }}
            />
          }
        />
      </div>
    </>
  );
}
