import React from "react";
import PageHelmet from "../../../../components/pageHelmet";
import TopNav from "../../components/topNav";
import Footer from "../../components/footer";
import SideNavBarLayout from "../../components/sideNavBarLayout";
import LogoutButton from "../../components/logoutButton";
import ProfileSideNav from "./ui/profileSideNav";
import MobileNavBarLayout from "../../components/mobileNavBarLayout";
import ProfileMobileNav from "./ui/profileMobileNav";
import WelcomeCardLayout from "../../components/welcomeCardLayout";
import ProfileOverview from "./ui/profileOverview";
import DashboardPageLayout from "../..";

export default function Profile() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Profile Overview"} />

      <TopNav />
      <DashboardPageLayout>
        <div className="flex flex-col lg:flex-row">
          <SideNavBarLayout>
            <ProfileSideNav />
            <LogoutButton />
          </SideNavBarLayout>
          <MobileNavBarLayout>
            <ProfileMobileNav />
          </MobileNavBarLayout>
          <div className="px-5 my-[50px] md:mt-[110px] md:px-10 lg:mt-[60px] lg:h-screen lg:flex-1">
            <WelcomeCardLayout>
              <ProfileOverview userType={"profile"} />
            </WelcomeCardLayout>
          </div>
        </div>
      </DashboardPageLayout>
      <Footer />
    </>
  );
}
