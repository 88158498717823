import React from "react";
import { Link } from "react-router-dom";
import {
  HiOutlineKey,
  HiOutlineDeviceMobile,
  HiOutlineLightBulb,
  HiOutlineWifi,
} from "react-icons/hi";

export function HowToUseSystem() {
  return (
    <>
      <div className="text-[#0B133E]">
        <div className="pt-5">
          <h1 className="text-2xl font-bold pb-5 text-nowrap">
            How To Use Our System
          </h1>
          <h2 className="font-semibold text-xl pb-1">Welcome to Robotrader</h2>
          <p>
            Welcome to our comprehensive guide to getting started with our
            system. This guide is designed to help you smoothly navigate the
            setup process, from acquiring your license key to configuring your
            device for optimal performance. We'll walk you through each feature,
            ensuring you understand how to use our tools effectively. Whether
            you're a new user or someone looking to explore more advanced
            functions, this guide will provide you with the knowledge and tips
            you need to fully leverage our system. Dive in, and let's get you up
            and running with ease and confidence.
          </p>
        </div>

        <div className="py-10">
          <h2 className="font-semibold text-xl pb-3">Before You Begin</h2>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-3">
              <HiOutlineKey className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
              <p className="">
                Ensure you have a license key from your mentor to access all
                system features.
              </p>
            </div>

            <div className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-3">
              <HiOutlineWifi className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
              <p>
                Ensure your phone remains connected at all times, through wifi
                or cellular network.
              </p>
            </div>

            <div className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-3">
              <HiOutlineDeviceMobile className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
              <p>
                Ensure your device is in good working condition and running
                Android 7.1 or higher.
              </p>
            </div>

            <div className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-3">
              <HiOutlineLightBulb className="w-[25px] h-[25px] lg:w-[40px] lg:h-[40px]" />
              <p>
                Ensure your phone remains unlocked and has the drop-down feature
                enabled.
              </p>
            </div>
          </div>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">Lorem, ipsum.</h2>
          <ul className="list-disc pl-5  flex flex-col gap-3">
            <li>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Et,
              perferendis.
            </li>

            <li>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Enim,
              distinctio!
            </li>
            <li>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit.
              Incidunt, iste!
            </li>
          </ul>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">Get in Touch</h2>
          <Link
            to={"https://wa.me/0614361548"}
            target="_blank"
            className="font-semibold text-sky-800 hover:underline underline-offset-4"
          >
            {" "}
            <em>WhatsApp at +27 61 436 1548</em>
          </Link>{" "}
        </div>
      </div>
    </>
  );
}
