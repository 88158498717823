import React from "react";
import "./button-loader.css";

export const ButtonLoader = ({ className, children }) => {
  return (
    <div className={`${className}`}>
      <div className={`buttonLoader`}></div>
      <p className="font-medium">{children}</p>
    </div>
  );
};
