import React from "react";

// Function to convert a numerical rating to a star rating
const getStarRating = (rating) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = 5 - Math.ceil(rating);

  return (
    <>
      {Array(fullStars)
        .fill("★")
        .map((star, index) => (
          <span key={index} className="text-yellow-500">
            {star}
          </span>
        ))}
      {hasHalfStar && <span className="text-yellow-500">☆</span>}
      {Array(emptyStars)
        .fill("☆")
        .map((star, index) => (
          <span
            key={index + fullStars + (hasHalfStar ? 1 : 0)}
            className="text-gray-300"
          >
            {star}
          </span>
        ))}
    </>
  );
};

const TableRow = ({ columns, isHeader }) => (
  <tr
    className={`grid grid-cols-[1fr,2fr,1fr,1fr,1fr] gap-5 lg:gap-9 w-full ${
      isHeader
        ? "mb-1"
        : "mb-3 p-2 py-4 bg-white shadow-md rounded-lg transition-transform duration-300 ease-in-out lg:hover:scale-105 hover:shadow-lg"
    }`}
  >
    {columns.map((column, index) => (
      <th
        key={index}
        className={`font-${isHeader ? "semibold" : "normal"} text-${
          index === 1 ? "left" : "center"
        } ${index !== columns.length - 1 && !isHeader ? "border-r-2" : ""}`}
      >
        {typeof column === "number" && index === 3
          ? getStarRating(column)
          : column}
      </th>
    ))}
  </tr>
);

const ReusableTable = ({ headers, rows }) => (
  <table className="w-full hidden md:grid">
    <thead>
      <TableRow columns={headers} isHeader />
    </thead>
    <tbody className="w-full py-3 rounded-lg">
      {rows.map((row, index) => (
        <TableRow key={index} columns={row} />
      ))}
    </tbody>
  </table>
);

export default function StatisticsTable() {
  const headers = ["Rank", "Course Name", "Students", "Rating", "Earning"];
  const rows = [
    ["1", "Intro to Programming", 45, 4.7, "$4.9K"],
    ["2", "Advanced Algorithms", 30, 2.9, "$6.2K"],
    ["3", "Web Development", 50, 4.9, "$5.4K"],
    ["4", "Intro to Programming", 45, 4.7, "$4.9K"],
    ["5", "Advanced Algorithms", 30, 2.9, "$6.2K"],
    ["6", "Web Development", 50, 4.9, "$5.4K"],
    ["7", "Intro to Programming", 45, 4.7, "$4.9K"],
    ["8", "Advanced Algorithms", 30, 2.9, "$6.2K"],
    ["9", "Web Development", 50, 4.9, "$5.4K"],
    ["10", "Intro to Programming", 45, 4.7, "$4.9K"],
  ];

  return (
    <div className="text-[#0B133E] w-full">
      <ReusableTable headers={headers} rows={rows} />
    </div>
  );
}
