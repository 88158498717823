import React from "react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";

export default function AdminsLink() {
  const { appData } = useAppContext();
  return (
    <div className="flex flex-col mt-[10px]">
      <p className="text-center text-[14px] ">
        Don't have an account?{" "}
        <Link to={`/${appData.name}/register/`}>
          <span className="text-blue-900 font-semibold md:hover:underline underline-offset-2">
            Register
          </span>{" "}
        </Link>
        or{" "}
        <Link to={`/${appData.name}/login/`}>
          <span className="text-blue-900 font-semibold md:hover:underline underline-offset-2">
            Login
          </span>
        </Link>
      </p>
    </div>
  );
}
