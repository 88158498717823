import React from "react";
import { Link } from "react-router-dom";

const InfoRow = ({ label, value }) => (
  <div className="flex justify-between items-center">
    <p className="font-semibold">{label}:</p>
    <Link to={`/manage-eas/ea-details`}>{value}</Link>
  </div>
);

const InfoCard = ({ data }) => (
  <div className="flex flex-col bg-sky-100 min-w-full p-4 gap-5 snap-start md:hidden rounded-lg mb-4">
    {data.map((item, index) => (
      <InfoRow key={index} label={item.label} value={item.value} />
    ))}
  </div>
);

const date = new Date();

export default function EACards() {
  const cardData = [
    [
      { label: "EA Name", value: "1113456" },
      { label: "Total Users", value: 7 },
      { label: "Active Users", value: 5 },
      { label: "Created On", value: date.getFullYear() },
    ],
    [
      { label: "EA Name", value: "2228908" },
      { label: "Total Users", value: 7 },
      { label: "Active Users", value: 5 },
      { label: "Created On", value: date.getFullYear() },
    ],
    [
      { label: "EA Name", value: "3331234" },
      { label: "Total Users", value: 7 },
      { label: "Active Users", value: 5 },
      { label: "Created On", value: date.getFullYear() },
    ],
    [
      { label: "EA Name", value: "4447678" },
      { label: "Total Users", value: 7 },
      { label: "Active Users", value: 5 },
      { label: "Created On", value: date.getFullYear() },
    ],
    [
      { label: "EA Name", value: "5551234" },
      { label: "Total Users", value: 7 },
      { label: "Active Users", value: 5 },
      { label: "Created On", value: date.getFullYear() },
    ],
    [
      { label: "EA Name", value: "6660981" },
      { label: "Total Users", value: 7 },
      { label: "Active Users", value: 5 },
      { label: "Created On", value: date.getFullYear() },
    ],
  ];

  return (
    <div className="flex gap-[20px] overflow-x-auto snap-x snap-mandatory">
      {cardData.map((data, index) => (
        <InfoCard key={index} data={data} />
      ))}
    </div>
  );
}
