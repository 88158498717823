import PageHelmet from "../../../../components/pageHelmet";
import AuthPageLayout from "../..";
import LoginForm from "./loginForm";
import { useAppContext } from "../../../../context/AppContext";


export default function Login() {
  const { appData } = useAppContext();
  return (
    <>
      <PageHelmet pageTitle={`${appData.name} - Login To Your Account`} />
      <AuthPageLayout>
        <LoginForm />
      </AuthPageLayout>
    </>
  );
}
