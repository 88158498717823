import React from "react";
import { Link } from "react-router-dom";

const TableRow = ({ columns, isHeader }) => (
  <tr
    className={`grid grid-cols-[1fr,1fr,1fr,1fr] gap-5 lg:gap-9 w-full ${
      isHeader
        ? "mb-1"
        : "mb-3 p-2 py-4 bg-white shadow-md rounded-lg  hover:shadow-lg"
    }`}
  >
    {columns.map((column, index) => (
      <th
        key={index}
        className={`font-${isHeader ? "semibold" : "normal"} text-${
          index === 1 ? "left" : "center"
        } ${index !== columns.length - 1 && !isHeader ? "border-r-2" : ""}`}
      >
        {index === 0 && !isHeader ? (
          <Link
            to={`/manage-eas/ea-details`}
            className="text-sky-500 underline underline-offset-4 hover:no-underline hover:text-sky-600"
          >
            {column}
          </Link>
        ) : (
          column
        )}
      </th>
    ))}
  </tr>
);

const ReusableTable = ({ headers, rows }) => (
  <table className="w-full hidden md:grid">
    <thead>
      <TableRow columns={headers} isHeader />
    </thead>
    <tbody className="w-full py-3 rounded-lg">
      {rows.map((row, index) => (
        <TableRow key={index} columns={row} />
      ))}
    </tbody>
  </table>
);

const date = new Date();

export default function EAsTable() {
  const headers = ["EA Name", "Total Users", "Active Users", "Created On"];
  const rows = [
    ["1112233", 7, 5, date.getFullYear()],
    ["2223321", 7, 5, date.getFullYear()],
    ["3334432", 7, 5, date.getFullYear()],
    ["4440090", 7, 5, date.getFullYear()],
    ["5556789", 7, 5, date.getFullYear()],
    ["6661234", 7, 5, date.getFullYear()],
  ];

  return (
    <div>
      <ReusableTable headers={headers} rows={rows} />
    </div>
  );
}
