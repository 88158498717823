import React from "react";
import { useNavigate } from "react-router-dom";
import AdminsLink from "../pages/auth/components/adminsLink";
import PageHelmet from "./pageHelmet";

export default function PageNotFound() {
  const navigate = useNavigate();

  const handleBackHome = () => {
    navigate(-1);
    window.location.reload();
  };

  return (
    <>
      <PageHelmet pageTitle={"Page Not Found"} />

      <div className="grid min-h-[100dvh] place-content-center md:rounded-l-[.8rem]">
        <div className="text-center">
          <h1 className="text-[11.1rem] leading-none mb-3 font-black text-gray-200">
            {"404"}
          </h1>

          <p className="text-7xl font-bold tracking-tight text-gray-900">
            {"Uh-oh!"}
          </p>

          <p className="mt-4 mb-5 text-2xl text-gray-500">
            {"We can't find that page."}
          </p>

          <button
            // type="button"
            onClick={handleBackHome}
            className="text-white font-semibold m-auto text-md border-2 border-blue-900 p-2 bg-blue-900 rounded-lg flex justify-center md:hover:bg-white md:hover:text-blue-900 md:hover:font-semibold w-[300px] md:w-[330px] lg:w-[360px] transition-transform duration-300 ease-in-out hover:scale-105"
          >
            {"Go Back Home"}
          </button>
        </div>

        <div className="w-[300px] md:w-[330px] lg:w-[360px] m-auto">
          <AdminsLink />
          
        </div>
      </div>
    </>
  );
}
