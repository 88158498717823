import React from "react";
import PageHelmet from "../../../../../components/pageHelmet";
import { Outlet, useLocation } from "react-router-dom";
import TopNav from "../../../components/topNav";
import Footer from "../../../components/footer";
import SideNavBarLayout from "../../../components/sideNavBarLayout";
import LogoutButton from "../../../components/logoutButton";
import MobileNavBarLayout from "../../../components/mobileNavBarLayout";
import AdminMobileNav from "../ui/adminMobileNav";
import AdminSideNav from "../ui/adminSideNav";
import DashboardPageLayout from "../../..";
import EarningsSummary from "../ui/earningsSummary";
import EarningsChart from "../ui/earningsChart";
import RecentTransactions from "../ui/recentTransactions";
// import Filters from "../ui/filters"; Component TODO
import ExportButton from "../ui/exportButton";
// import { transactions } from "../ui/recentTransactions";

export default function Earnings() {
  const location = useLocation();
  const isNestedRoute = location.pathname.includes("/earnings/");
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - View Your Earnings"} />

      <TopNav />
      <DashboardPageLayout>
        <div className="flex flex-col lg:flex-row">
          <SideNavBarLayout>
            <AdminSideNav />
            <LogoutButton />
          </SideNavBarLayout>
          <MobileNavBarLayout>
            <AdminMobileNav />
          </MobileNavBarLayout>
          <div className="px-5 mt-[50px] md:mt-[110px] md:px-10 lg:mt-[60px]  md:min-h-screen lg:flex-1">
            {isNestedRoute ? (
              <Outlet />
            ) : (
              <>
                <h1 className="text-xl text-[#0B133E] font-semibold pt-5 lg:pt-0">
                  Manage Earnings
                </h1>

                <div className="flex flex-col gap-5 mb-10">
                  <EarningsSummary />
                  <EarningsChart />
                  <RecentTransactions />
                  {/* <Filters onFilter={transactions} /> *Component TODO*/}
                  <ExportButton />
                </div>
              </>
            )}
          </div>
        </div>
      </DashboardPageLayout>
      <Footer />
    </>
  );
}
