import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import {
  AtSymbolIcon,
  UserCircleIcon,
  UserGroupIcon,
  DevicePhoneMobileIcon,
  BanknotesIcon,
  ArrowRightCircleIcon,
  ArrowLeftCircleIcon,
} from "@heroicons/react/24/outline";
import { ConfirmationModal, AlertModal } from "../ui/confirmationModals";
import { CreateButton } from "../ui/buttons";

export default function EditMentor() {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phone: "",
    students: "",
    earnings: "",
  });
  const location = useLocation();
  const navigate = useNavigate();
  const mentor = location.state?.mentor;

  useEffect(() => {
    if (mentor) {
      setFormData({
        fullname: mentor.name || "",
        email: mentor.email || "",
        phone: mentor.phone || "",
        students: mentor.students || "",
        earnings: `R${mentor.earnings}K` || "",
      });
    } else {
      navigate("/manage-mentors");
    }
  }, [mentor, navigate]);

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const handleConfirm = () => {
    setShowConfirmModal(false);
    setShowSuccessMessage(true);

    setTimeout(() => {
      setShowSuccessMessage(false);
      navigate(`/manage-mentors`);
    }, 3000);
  };

  const handleCancel = () => {
    setShowConfirmModal(false);
    setShowSuccessMessage(false);
    navigate(`/manage-mentors`);
  };

  const handleSubmit = (ev) => {
    if (ev) ev.preventDefault();
    setShowConfirmModal(true);
  };

  return (
    <>
      <h1 className="text-xl font-semibold pt-5 lg:pt-0 text-[#0B133E]">
        <Link to={"/manage-mentors"} className="text-gray-600 font-normal">
          Manage Mentors
        </Link>{" "}
        / Edit Mentor
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="rounded-md bg-gray-50 mt-5 p-4 md:p-6 font-medium text-[#0B133E]">
          <label htmlFor="fullname">Full Name</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.fullname}
              id="fullname"
              name="fullname"
              type="text"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
              placeholder="Enter mentor's full name"
            />
            <UserCircleIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
          </div>

          <label htmlFor="email">Email</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.email}
              id="email"
              name="email"
              type="text"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
              placeholder="Enter mentor's email"
            />
            <AtSymbolIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
          </div>

          <label htmlFor="phone">Phone Number</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.phone}
              id="phone"
              name="phone"
              type="tel"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
              placeholder="Enter mentor's phone number"
            />
            <DevicePhoneMobileIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
          </div>

          <label htmlFor="students">Students</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.students}
              id="students"
              name="students"
              type="number"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
              placeholder="Enter number of students"
              readOnly
            />
            <UserGroupIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
          </div>

          <label htmlFor="earnings">Earnings</label>
          <div className="relative rounded-md">
            <input
              onChange={handleChange}
              value={formData.earnings}
              id="earnings"
              name="earnings"
              type="text"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-10 text-sm outline-2 placeholder:text-gray-500"
              placeholder="Mentor's earnings"
              readOnly
            />
            <BanknotesIcon
              className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500"
              style={{ strokeWidth: 2 }}
            />
          </div>
        </div>
        <div className="mb-10 mt-5 flex justify-end gap-5">
          <Link to="/manage-mentors">
            <CreateButton
              className="flex flex-row-reverse py-2 bg-sky-200 hover:bg-sky-300"
              children={"Cancel"}
              icon={
                <ArrowLeftCircleIcon
                  className="h-5 w-5"
                  style={{ strokeWidth: 2 }}
                />
              }
            />
          </Link>
          <CreateButton
            className="py-2 bg-sky-200 hover:bg-sky-300"
            children={"Confirm Edit"}
            icon={
              <ArrowRightCircleIcon
                className="h-5 w-5"
                style={{ strokeWidth: 2 }}
              />
            }
          />
        </div>
      </form>

      {showConfirmModal && (
        <ConfirmationModal
          children={`Edit ${formData.fullname} as a Mentor?`}
          handleCancel={handleCancel}
          handleConfirm={handleConfirm}
        />
      )}

      {showSuccessMessage && (
        <AlertModal
          children={`Mentor Updated Successfully`}
          handleCancel={handleCancel}
        />
      )}
    </>
  );
}
