import {
  ArrowLeftIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { CreateButton } from "../ui/buttons";
// import { format, parseISO } from "date-fns";

// Example validation schema (you might use a library like yup for more complex validations)
const validateAmount = (value) =>
  value > 0 || "Amount must be greater than zero";

// Example form component
export default function EnterEarningsForm({ onSubmit }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitHandler = (data) => {
    // Handle form submission
    console.log(data);
    if (onSubmit) onSubmit(data);
  };

  return (
    <>
      <div className="md:flex pt-5 justify-between items-center flex-row-reverse lg:pt-0">
        <Link
          to={"/earnings"}
          className="hidden md:block mr-5 rounded-full border bottom-0 hover:bg-gray-100"
        >
          <ArrowLeftIcon className="h-6 w-7 " />
        </Link>
        <div className="text-xl font-semibold lg:pt-0 text-[#0B133E]">
          <Link to={"/earnings"} className="text-gray-600 font-normal">
            Manage Earnings
          </Link>{" "}
          / Enter Earnings
        </div>{" "}
      </div>

      <form onSubmit={handleSubmit(onSubmitHandler)} className="">
        {/* Amount Field */}
        <div className="rounded-md bg-gray-50 mt-5 p-4 md:p-6 font-medium text-[#0B133E]">
          <div className="">
            <label htmlFor="amount" className="block text-gray-700">
              Amount
            </label>
            <input
              id="amount"
              type="number"
              step="0.01"
              {...register("amount", {
                required: "Amount is required",
                validate: validateAmount,
              })}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
            />
            {errors.amount && (
              <p className="error-message">{errors.amount.message}</p>
            )}
          </div>

          {/* Date Field */}
          <div className="mb-4">
            <label htmlFor="date" className="block text-gray-700">
              Date
            </label>
            <input
              id="date"
              type="date"
              {...register("date", { required: "Date is required" })}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
            />
            {errors.date && (
              <p className="error-message">{errors.date.message}</p>
            )}
          </div>

          {/* Description Field */}
          <div className="mb-4">
            <label htmlFor="description" className="block text-gray-700">
              Description
            </label>
            <textarea
              id="description"
              {...register("description")}
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              rows="4"
            />
          </div>
        </div>
        <div className="flex justify-end mb-10">
          <CreateButton
            className="mt-5 py-2 bg-sky-200 hover:bg-sky-300"
            children={"Submit"}
            icon={
              <ArrowRightCircleIcon
                className="w-5 h-5"
                style={{ strokeWidth: 2 }}
              />
            }
          />
        </div>
      </form>
    </>
  );
}
