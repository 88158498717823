import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppContext } from "../../../context/AppContext";

export default function LogoutButton() {
  const { appData } = useAppContext();
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/authentication/logout/`,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const data = await response.json();

      if (!response.ok) {
        setErrorMessage(data.message);
      } else {
        navigate(`/${appData.name}/login`);
      }
    } catch (err) {
      console.log(`Error: ${err}`);
    }
  };

  return (
    <Link onClick={handleLogout}>
      <div className="flex items-center lg:justify-start lg:border-t-2 lg:w-[93%] lg:m-auto lg:mt-[5%]">
        <button
          type="button"
          className="flex flex-col items-center lg:flex-row lg:items-center lg:my-5 lg:gap-2 lg:hover:bg-sky-100 lg:w-full lg:rounded-md lg:py-2 lg:pl-10 "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.636 5.636a9 9 0 1 0 12.728 0M12 3v9"
            />
          </svg>

          <p className="text-[10px] font-semibold text-[#0B133E] lg:text-[16px]">
            {errorMessage && <span>{errorMessage}</span>}
            Log Out
          </p>
        </button>
      </div>
    </Link>
  );
}
