import React from "react";

const getStarRating = (rating) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = 5 - Math.ceil(rating);

  return (
    <>
      {Array(fullStars)
        .fill("★")
        .map((star, index) => (
          <span key={index} className="text-yellow-500">
            {star}
          </span>
        ))}
      {hasHalfStar && <span className="text-yellow-500">☆</span>}
      {Array(emptyStars)
        .fill("☆")
        .map((star, index) => (
          <span
            key={index + fullStars + (hasHalfStar ? 1 : 0)}
            className="text-gray-300"
          >
            {star}
          </span>
        ))}
    </>
  );
};

const InfoRow = ({ label, value }) => (
  <div className="flex justify-between items-center">
    <p className="font-semibold">{label}:</p>
    <p>{label === "Rating" ? getStarRating(parseFloat(value)) : value}</p>
  </div>
);

const InfoCard = ({ data }) => (
  <div className="flex flex-col bg-sky-100 min-w-full p-4 gap-5 snap-start md:hidden rounded-lg mb-4">
    {data.map((item, index) => (
      <InfoRow key={index} label={item.label} value={item.value} />
    ))}
  </div>
);

export default function StatisticsMobileTableView() {
  const cardData = [
    [
      { label: "Rank", value: "1" },
      { label: "Course Name", value: "Intro to Prog..." },
      { label: "Rating", value: "4.7" },
      { label: "Earning", value: "$4.9K" },
    ],
    [
      { label: "Rank", value: "2" },
      { label: "Course Name", value: "Advanced Alg..." },
      { label: "Rating", value: "2.9" },
      { label: "Earning", value: "$6.2K" },
    ],
    [
      { label: "Rank", value: "3" },
      { label: "Course Name", value: "Web Develop..." },
      { label: "Rating", value: "4.9" },
      { label: "Earning", value: "$5.4K" },
    ],
    [
      { label: "Rank", value: "4" },
      { label: "Course Name", value: "Intro to Prog..." },
      { label: "Rating", value: "4.7" },
      { label: "Earning", value: "$4.9K" },
    ],
    [
      { label: "Rank", value: "5" },
      { label: "Course Name", value: "Advanced Alg..." },
      { label: "Rating", value: "2.9" },
      { label: "Earning", value: "$6.2K" },
    ],
    [
      { label: "Rank", value: "6" },
      { label: "Course Name", value: "Web Develop..." },
      { label: "Rating", value: "4.9" },
      { label: "Earning", value: "$5.4K" },
    ],
    [
      { label: "Rank", value: "7" },
      { label: "Course Name", value: "Intro to Prog..." },
      { label: "Rating", value: "4.7" },
      { label: "Earning", value: "$4.9K" },
    ],
    [
      { label: "Rank", value: "8" },
      { label: "Course Name", value: "Advanced Alg..." },
      { label: "Rating", value: "2.9" },
      { label: "Earning", value: "$6.2K" },
    ],
    [
      { label: "Rank", value: "9" },
      { label: "Course Name", value: "Web Develop..." },
      { label: "Rating", value: "4.9" },
      { label: "Earning", value: "$5.4K" },
    ],
    [
      { label: "Rank", value: "10" },
      { label: "Course Name", value: "Intro to Prog..." },
      { label: "Rating", value: "4.7" },
      { label: "Earning", value: "$4.9K" },
    ],
  ];

  return (
    <div className="flex gap-[20px] overflow-x-auto snap-x snap-mandatory">
      {cardData.map((data, index) => (
        <InfoCard key={index} data={data} />
      ))}
    </div>
  );
}
