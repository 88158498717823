import React from "react";
import { FaBook, FaUserGraduate, FaStar, FaWallet } from "react-icons/fa";

export default function OverviewCards() {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-3">
      <section className="flex flex-col items-center gap-1 bg-white p-5 rounded-lg justify-center py-10 md:flex-row transition-transform duration-300 ease-in-out lg:hover:scale-105 lg:hover:shadow-lg">
        <div className="bg-red-400 p-4 text-lg rounded-full text-sky-100 lg:text-2xl">
          <FaBook />
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-gray-400 text-lg">Courses</h3>
          <p className="font-bold text-[23px]">21</p>
        </div>
      </section>

      <section className="flex flex-col items-center gap-1 bg-white p-5 rounded-lg justify-center py-10 md:flex-row transition-transform duration-300 ease-in-out lg:hover:scale-105 lg:hover:shadow-lg">
        <div className="bg-orange-400 p-4 text-lg rounded-full text-sky-100 lg:text-2xl">
          <FaUserGraduate />
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-gray-400 text-lg">Students</h3>
          <p className="font-bold text-[23px]">12.5K</p>
        </div>
      </section>

      <section className="flex flex-col items-center gap-1 bg-white p-5 rounded-lg justify-center py-10 md:flex-row transition-transform duration-300 ease-in-out lg:hover:scale-105 lg:hover:shadow-lg">
        <div className="bg-green-400 p-4 text-lg rounded-full text-sky-100 lg:text-2xl">
          <FaStar />
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-gray-400 text-lg">Ratings</h3>
          <p className="font-bold text-[23px]">4.9</p>
        </div>
      </section>

      <section className="flex flex-col items-center gap-1 bg-white p-5 rounded-lg justify-center py-10 md:flex-row transition-transform duration-300 ease-in-out lg:hover:scale-105 lg:hover:shadow-lg">
        <div className="bg-blue-400 p-4 text-lg rounded-full text-sky-100 lg:text-2xl">
          <FaWallet />
        </div>
        <div className="flex flex-col items-center">
          <h3 className="text-gray-400 text-lg">Earnings</h3>
          <p className="font-bold text-[23px]">$5.8K</p>
        </div>
      </section>
    </div>
  );
}
