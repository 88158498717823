import React from "react";
import PageHelmet from "../../../../components/pageHelmet";
import TopNav from "../../components/topNav";
import Footer from "../../components/footer";
import SideNavBarLayout from "../../components/sideNavBarLayout";
import LogoutButton from "../../components/logoutButton";
import MentorSideNav from "./ui/mentorSideNav";
import WelcomeCardLayout from "../../components/welcomeCardLayout";
import MobileNavBarLayout from "../../components/mobileNavBarLayout";
import MentorMobileNav from "./ui/mentorMobileNav";
import MentorWelcomeCard from "./ui/mentorWelcomeCard";
import { OverviewHeading } from "../../components/overviewHeading";
import OverviewCards from "../../components/overviewCards";
import Statistics from "../../components/statistics";
import DashboardPageLayout from "../..";

export default function MentorPage() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Mentor"} />
      <DashboardPageLayout>
        <TopNav />
        <div className="flex flex-col lg:flex-row">
          <SideNavBarLayout>
            <MentorSideNav />
            <LogoutButton />
          </SideNavBarLayout>
          <MobileNavBarLayout>
            <MentorMobileNav />
          </MobileNavBarLayout>
          <div className="px-5 mt-[50px] md:mt-[110px] md:px-10 lg:mt-[60px]  md:min-h-screen lg:flex-1">
            <WelcomeCardLayout>
              <MentorWelcomeCard userType={"mentor"} username={"Sarah Smith"} />
            </WelcomeCardLayout>
            <OverviewHeading />
            <OverviewCards />
            <Statistics />
          </div>
        </div>
      </DashboardPageLayout>
      <Footer />
    </>
  );
}
