import React from "react";
import Image from "../../../../../assets/img/logo.jpeg";

export default function AdminWelcomeCard({ userType, username }) {
  const greeting =
    userType === "admin" ? "Welcome back Admin" : "Welcome back Mentor";

  const messageType =
    userType === "admin" ? (
      <>
        <p className="md:hidden lg:hidden">
          As an administrator, you can manage mentors and students alike.
        </p>
        <p className="hidden md:block">
          As an administrator, you can manage <br /> mentors and students alike.
        </p>
      </>
    ) : (
      <>
        <p className="md:hidden lg:hidden">
          As a mentor, you can manage your students, distribute license keys,
          and much more...
        </p>
        <p className="hidden md:block">
          As a mentor, you can manage your students, <br />
          distribute license keys, and much more...
        </p>
      </>
    );

  return (
    <>
      <section className="p-5 rounded-t-xl text-[#0B133E] bg-sky-300 md:w-[75%] md:rounded-l-xl md:rounded-tr-none lg:py-[63px]">
        <h2 className="font-semibold text-xl lg:text-2xl mb-2">{greeting},</h2>
        <h2 className="font-semibold text-xl lg:text-2xl mb-5">{username}!</h2>
        <div className="font-normal">{messageType}</div>
      </section>

      <div className="rounded-b-xl bg-green-200 md:w-[25%] md:rounded-bl-none md:rounded-r-xl">
        <div className="flex justify-center">
          <img
            src={Image}
            alt="Profile"
            className="w-[200px] mt-[20px] rounded-t-xl md:mr-[170px] md:mt-[15px] md:h-[157px] lg:hidden"
          />

          <img
            src={Image}
            alt="Profile"
            className="hidden max-w-[220px] rounded-t-xl lg:block lg:mr-[240px] lg:mt-[46px]"
          />
        </div>
      </div>
    </>
  );
}
