import { useState } from "react";
import Credentials from "../../components/credentials";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../components/buttons";
import { ButtonLoader } from "../../../../components/buttonLoader";
import { useAppContext } from "../../../../context/AppContext";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";

export default function RegisterForm() {
  const { appData } = useAppContext();

  const [formData, setFormData] = useState({
    name: "",
    displayname: "",
    email: "",
    phone: "",
    password: "",
    my_app: appData.name,
    checkbox: false,
  });

  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: undefined });
  };

  const handleCheckboxChange = (ev) => {
    const isChecked = ev.target.checked;
    setFormData((prev) => ({
      ...prev,
      checkbox: isChecked,
    }));
    if (isChecked) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        checkbox: undefined,
      }));
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setIsLoading(true);

    // Validation logic...
    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = `Full Name is required!`;
    }

    if (!formData.displayname.trim()) {
      validationErrors.displayname = "Display Name is required!";
    }

    if (!formData.email.trim()) {
      validationErrors.email = "Email is required!";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = "Email must be valid!";
    }

    if (!formData.phone) {
      validationErrors.phone = "Phone number is required!";
    } else if (!/^\d+$/.test(formData.phone)) {
      validationErrors.phone = "Phone number must contain digits only!";
    }

    if (!formData.password.trim()) {
      validationErrors.password = "Password is required!";
    } else if (formData.password.length < 8) {
      validationErrors.password = "Password must be at least 8 characters!";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password)) {
      validationErrors.password =
        "Password must contain at least one special character!";
    } else if (!/[A-Z]/.test(formData.password)) {
      validationErrors.password =
        "Password must contain at least one uppercase letter!";
    } else if (!/[0-9]/.test(formData.password)) {
      validationErrors.password = "Password must contain at least one number!";
    }

    if (formData.checkbox === false) {
      validationErrors.checkbox = "Please agree to the terms of service!";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const otpResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/authentication/otp/?email=${formData.email}&app=${appData.name}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        ).then((response) => {
          setIsLoading(false);
          return response.json();
        });

        if (otpResponse.status !== "success") {
          setErrorMessage(otpResponse.message);
        } else {
          const otp = otpResponse.data.otp;
          setErrorMessage(null);

          navigate(`/${appData.name}/email-verification/`, {
            state: { otp: otp, data: formData },
          });
        }
      } catch (err) {
        console.error(`Error: ${err.message}`);
        setErrorMessage("Unknown Error! Try Again.");
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col items-center justify-center min-h-[100dvh]">
        {!isLoading && errorMessage ? (
          <div
            id="error_msg"
            className="bg-red-700 text-red-100 px-3 font-semibold rounded-lg mx-4 mb-10"
          >
            {errorMessage}
          </div>
        ) : null}

        <h1 className="text-center text-xl">Register your account</h1>

        <div className="flex flex-col w-[300px] md:w-[330px] lg:w-[360px]">
          <div className="flex flex-col gap-[5.7px]">
            <div className="flex flex-col gap-1">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-1 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                value={formData.name}
                type="text"
                name="name"
                required
                autoComplete="off"
                placeholder="Full Name"
                className="border font-medium border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
              {errors.name && (
                <span className="error-message" role="alert">
                  {setIsLoading(false)}
                  {errors.name}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-1 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                value={formData.displayname}
                type="text"
                name="displayname"
                required
                autoComplete="off"
                placeholder="Display Name"
                className="border font-medium border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
              {errors.displayname && (
                <span className="error-message" role="alert">
                  {errors.displayname}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-1 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                value={formData.email}
                type="email"
                name="email"
                required
                autoComplete="off"
                placeholder="Email"
                className="border font-medium border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
              {errors.email && (
                <span className="error-message" role="alert">
                  {errors.email}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-1 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                value={formData.phone}
                type="tel"
                name="phone"
                required
                autoComplete="off"
                placeholder="Phone Number"
                className="border font-medium border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
              {errors.phone && (
                <span className="error-message" role="alert">
                  {errors.phone}
                </span>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-1 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                value={formData.password}
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="off"
                required
                placeholder="Password"
                className="border font-medium border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
              <div
                className="relative top-[-35px] left-[270px] md:top-[-35px] md:left-[18.5rem] lg:left-[20.5rem]"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeIcon className="h5 w-5 absolute" />
                ) : (
                  <EyeSlashIcon className="h5 w-5 absolute" />
                )}
              </div>
              {errors.password && (
                <span className="error-message" role="alert">
                  {errors.password}
                </span>
              )}
            </div>
          </div>
          <div className="my-[10px]">
            <label className="flex items-center gap-1">
              <input
                onChange={handleCheckboxChange}
                checked={formData.checkbox}
                name="checkbox"
                type="checkbox"
              />
              <p className=" text-[12px]">
                I agree to the Terms & Conditions of service
              </p>
            </label>
            {errors.checkbox && (
              <span className="error-message" role="alert">
                {errors.checkbox}
              </span>
            )}
          </div>

          <Button children={isLoading ? <ButtonLoader /> : "Register"} />

          <div className="flex flex-col my-[10px]">
            <p className="text-[14px] text-center ">
              Already have an account?{" "}
              <Link to={`/${appData.name}/login`}>
                {" "}
                <span className="text-blue-900 font-semibold md:hover:underline underline-offset-2">
                  Log in
                </span>
              </Link>
            </p>

            <Credentials />
          </div>
        </div>
      </div>
    </form>
  );
}
