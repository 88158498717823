import React, { useState } from "react";

// Mock data for illustration purposes
export const transactions = [
  { id: 1, date: "09-01", description: "Item 1", amount: 5 },
  { id: 2, date: "09-02", description: "Item 2", amount: 7.5 },
  { id: 3, date: "09-03", description: "Item 3", amount: 2 },
  { id: 4, date: "09-04", description: "Item 4", amount: 1.5 },
  { id: 5, date: "09-05", description: "Item 5", amount: 3 },
  // Add more mock data as needed
];

export default function RecentTransactions() {
  const [sortOrder, setSortOrder] = useState("desc");

  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Sort transactions based on sortOrder
  const sortedTransactions = [...transactions].sort((a, b) => {
    if (sortOrder === "asc") {
      return new Date(a.date) - new Date(b.date);
    }
    return new Date(b.date) - new Date(a.date);
  });

  return (
    <div className="bg-white shadow-md rounded-lg p-6">
      <h2 className="text-xl font-bold mb-4 text-[#0B133E]">
        Recent Transactions
      </h2>
      <button
        className="bg-sky-200 hover:bg-sky-300 text-[#0B133E] font-semibold px-4 py-2 rounded mb-4"
        onClick={handleSort}
      >
        Sort by Date: {sortOrder === "asc" ? "Ascending" : "Descending"}
      </button>
      <table className="w-full border-collaps">
        <thead>
          <tr>
            <th className="border-b py-2 px-4 text-left text-[#0B133E]">
              Date
            </th>
            <th className="border-b py-2 px-4 text-center text-[#0B133E]">
              Description
            </th>
            <th className="border-b py-2 px-4 text-right text-[#0B133E]">
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedTransactions.map((transaction) => (
            <tr key={transaction.id}>
              <td className="border-b py-2 px-4 font-semibold text-gray-700 text-nowrap">
                {transaction.date}
              </td>
              <td className="border-b py-2 px-4 font-semibold text-gray-700 text-center">
                {transaction.description}
              </td>
              <td className="border-b py-2 px-4 text-right font-semibold text-gray-700">
                R{transaction.amount.toLocaleString()}K
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
