import React from "react";
import StatisticsMobileTableView from "./statsMobileTableView";
import StatisticsTableView from "./statsTableView";

export default function Statistics() {
  return (
    <div className="text-[#0B133E] w-full md:mb-[50px]">
      <section className="my-5">
        <h2 className="text-xl font-semibold mb-5">Course Statistics</h2>
        <StatisticsMobileTableView />
        <StatisticsTableView />
      </section>
    </div>
  );
}
