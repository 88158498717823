// import { useAppContext } from "../../context/AppContext";
import "./index.css";

export default function AuthPageLayout({ children }) {
  return (
    <div className="bg-image md:flex md:flex-row-reverse md:items-center">
      <div className="shadow-lg  h-full overflow-auto  md:bg-white md:w-[60%] md:rounded-l-[.8rem] lg:w-[50%]">
        {children}
      </div>
    </div>
  );
}
