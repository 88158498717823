import { useState } from "react";
import Credentials from "../../components/credentials";
import { Link, useNavigate } from "react-router-dom";
import LoginButton from "../../components/buttons";
import { useAppContext } from "../../../../context/AppContext";
import { ButtonLoader } from "../../../../components/buttonLoader";

export default function LoginForm() {
  const { appData } = useAppContext();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    app: appData.name,
  });

  const [inputErrors, setInputErrors] = useState({});

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData({ ...formData, [name]: value });
    setInputErrors({ ...inputErrors, [name]: undefined });
    setErrorMessage("");
  };

  const handleSubmit = async (ev) => {
    setIsLoading(true);
    ev.preventDefault();
    const validationErrors = {};
    if (!formData.email) {
      validationErrors.email = "Email is required!";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = "Email must be valid!";
    }

    if (!formData.password) {
      validationErrors.password = "Password is required!";
    }

    setInputErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const loginResponse = await fetch(
          `${process.env.REACT_APP_BASE_URL}/authentication/login/`,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        const data = await loginResponse.json();

        if (data.status !== "success") {
          setErrorMessage(data.message);
        } else {
          navigate(`/${appData.name}/`); // Redirect to the dashboard after successfull login...
        }
      } catch (err) {
        setIsLoading(false);
        setErrorMessage("Unknown Error! Try Again.");
        console.error("Error: ", err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col items-center justify-center min-h-[100dvh]">
        <div className="flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1"
            stroke="currentColor"
            className="text-blue-900 size-[44.8%]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
            />
          </svg>

          <h1 className="text-center text-xl font-normal">
            Login to your account
          </h1>

          {!isLoading && errorMessage ? (
            <div
              id="error_msg"
              className=" flex flex-col w-[300px] md:w-[330px] lg:w-[360px] bg-red-700 text-red-100 px-3 font-semibold rounded-lg mx-4 my-5 text-center"
            >
              {errorMessage}
            </div>
          ) : null}
        </div>

        <div className="flex flex-col w-[300px] md:w-[330px] lg:w-[360px] font-medium">
          <div className="flex flex-col gap-[6px]">
            <div className="flex flex-col gap-1">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-1 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                value={formData.email}
                type="email"
                name="email"
                required
                autoComplete="off"
                placeholder="Email"
                className="border border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
              {inputErrors.email && (
                <span className="error-message">{inputErrors.email}</span>
              )}
            </div>

            <div className="flex flex-col gap-1">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-1 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                value={formData.password}
                type="password"
                name="password"
                autoComplete="off"
                required
                placeholder="Password"
                className="border border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
              {inputErrors.password && (
                <span className="error-message">{inputErrors.password}</span>
              )}
            </div>
          </div>

          <p className="text-[14px] my-[8px] text-center p-1">
            Forgot password?{" "}
            <Link to={`/${appData.name}/reset-password`}>
              {" "}
              <span className="text-blue-900 font-semibold md:hover:underline underline-offset-2">
                Reset here
              </span>
            </Link>
          </p>

          <LoginButton children={isLoading ? <ButtonLoader /> : "Login"} />

          <div className="flex gap-1 justify-center items-center">
            <span className="w-[46%]">
              <hr />
            </span>
            <p className="text-[16px] text-center w-[8%]">or</p>
            <span className="w-[46%]">
              <hr />
            </span>
          </div>

          <Link
            to={`/${appData.name}/account-activation`}
            className="text-black font-semibold border-2 hover:border-blue-900 text-sm border-gray-200 p-2 bg-gray-200 rounded-lg flex justify-center md:hover:bg-white md:hover:text-blue-900 md:hover:font-semibold transition-transform duration-300 ease-in-out hover:scale-105 hover:shadow-lg"
          >
            Activate your account
          </Link>

          <div className="flex flex-col my-[10px]">
            <p className="text-[14px] text-center ">
              Don't have an account?{" "}
              <Link to={`/${appData.name}/register`}>
                {" "}
                <span className="text-blue-900 font-semibold md:hover:underline underline-offset-2">
                  Register here
                </span>
              </Link>
            </p>

            <Credentials />
          </div>
        </div>
      </div>
    </form>
  );
}
