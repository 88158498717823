import React from "react";
import { Link } from "react-router-dom";
import {
  ArrowRightCircleIcon,
  ArrowLeftCircleIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import { CreateButton } from "../../admin/ui/buttons";

export default function AddEA() {
  return (
    <>
      <div className="text-xl mt-5 font-semibold lg:pt-0 text-[#0B133E] lg:mt-0">
        <Link to={"/manage-eas"} className="text-gray-600 font-normal">
          Manage EAs
        </Link>{" "}
        / New EA
      </div>{" "}
      <form onSubmit={""}>
        <div className="rounded-md bg-white mt-5 p-4 md:p-6 font-medium text-[#0B133E]">
          <h2 className="font-semibold text-xl mb-10">
            Add a new EA to your key
          </h2>

          <label htmlFor="ea-name">EA Name</label>
          <div className="relative rounded-md">
            <input
              id="ea-name"
              name="ea-name"
              type="text"
              placeholder="Full EA name plus version number"
              className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-8 text-sm outline-2 placeholder:text-gray-500"
            />
            <BoltIcon className="absolute left-3 top-2.5 h-[18px] w-[18px] text-gray-500 " />
          </div>

          <div className="mt-[20px] mb-3">
            <label className="flex items-center gap-1">
              <input name="checkbox" type="checkbox" className="" />
              <p className=" text-sm">I confirm that I am an admin</p>
            </label>
          </div>
        </div>
        <div className="mb-10 mt-5 flex justify-end gap-5">
          <Link to="/manage-eas">
            <CreateButton
              children={"Cancel"}
              icon={
                <ArrowLeftCircleIcon
                  className="h-5 w-5"
                  style={{ strokeWidth: 2 }}
                />
              }
              className="flex flex-row-reverse py-2 bg-sky-200 hover:bg-sky-300"
            />
          </Link>
          <CreateButton
            icon={
              <ArrowRightCircleIcon
                className="h-5 w-5"
                style={{ strokeWidth: 2 }}
              />
            }
            children={"Add EA"}
            className="py-2 bg-sky-200 hover:bg-sky-300"
          />
        </div>
      </form>
    </>
  );
}
