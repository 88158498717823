import React from "react";
import { Link } from "react-router-dom";
import {
  FaWhatsapp,
  FaTelegramPlane,
  FaFacebook,
  FaTiktok,
  FaInstagram,
  FaArrowUp,
} from "react-icons/fa";

export default function FooterSocialIcons() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="flex items-center justify-between pt-10 md:pt-20">
      <div className="flex items-center p-5 gap-2 md:gap-3 justify-start md:py-5 md:p-0">
        <Link to={"https://wa.me/0614361548"} target="_blank">
          <FaWhatsapp size={22} className="text-sky-100 hover:text-[#25D366]" />
        </Link>

        <Link to={"https://t.me/robotrader-bot"} target="_blank">
          <FaTelegramPlane
            size={23}
            className="text-sky-100 hover:text-[#24A1DE]"
          />
        </Link>

        <Link to={"/facebook"} target="_blank">
          <FaFacebook size={21} className="text-sky-100 hover:text-[#4267B2]" />
        </Link>

        <Link to={"/tiktok"} target="_blank">
          <FaTiktok size={20} className="text-sky-100 hover:text-[#FF0050]" />
        </Link>

        <Link to={"/instagram"} className="pr-5 md:pr-0">
          <FaInstagram size={20} className="text-sky-100 hover:text-[#D62976]" />
        </Link>
      </div>

      <button type="button" onClick={scrollToTop} className="mr-2 md:mr-0">
        <FaArrowUp
          size={25}
          className="text-sky-100 border p-1 rounded-full hover:border-[#25D366]  hover:text-[#25D366]"
        />
      </button>
    </div>
  );
}
