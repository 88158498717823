import React, { useState, useEffect } from "react";
import Image from "../../../../../assets/img/logo.jpeg";
import ProfileBio from "./profileBio";
import flag from "../../../../../assets/img/south-africa.png";
import { Loader } from "../../../../../components/loader";

export default function ProfileOverview({ userType }) {
  const profile = userType === "profile" ? "Welcome back to your profile" : "";

  const [userData, setUserData] = useState(null);
  // const [imageData, setImageData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userResponse] = await Promise.all([
          fetch(`${process.env.REACT_APP_USER_API}/users/1`),
          // fetch(`${process.env.REACT_APP_USER_API}/photos/2`), TODO profile image from database...
        ]);

        if (!userResponse.ok) {
          throw new Error(`Network response was not ok.`);
        }

        const userData = await userResponse.json();
        // const imageData = await imageResponse.json(); profile image from DB...

        setUserData(userData);
        // setImageData(imageData); profile image from DB...
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {loading ? (
        <div className="m-auto">
          <Loader
            className={
              "h-[50dvh] flex flex-col items-center justify-center gap-2"
            }
          />
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="md:flex">
            <section className="p-5 rounded-t-xl text-[#0B133E] bg-sky-300 md:w-[75%] md:rounded-l-xl md:rounded-tr-none lg:py-[63px]">
              <h2 className="font-semibold text-xl lg:text-2xl mb-2">
                {profile},
              </h2>
              <h2 className="font-semibold text-xl lg:text-2xl mb-5">
                {userData.name}!
              </h2>
              <p className="font-semibold text-gray-700">
                Profile overview...
                <br />
                Personalize your profile and settings
              </p>
            </section>
            <div className="rounded-b-xl bg-green-300 md:w-[25%] md:rounded-bl-none md:rounded-r-xl">
              <div className="flex justify-center">
                <img
                  src={Image}
                  alt="Profile"
                  className="w-[200px] mt-[25px] rounded-t-xl md:mr-[170px] md:mt-[15px] md:h-[157px] lg:hidden"
                />

                <img
                  src={Image}
                  alt="Profile"
                  className="hidden max-w-[220px] rounded-t-xl lg:block lg:mr-[240px] lg:mt-[46px]"
                />
              </div>
            </div>
          </div>
          <ProfileBio
            userGender={"Female"}
            userAge={27}
            userStatus={"Active"}
            username={userData.name}
            userAddress={"Palm Springs, 2133 Johannesburg (SA)"}
            userRole={"Administrator"}
            userContact={"+27 78 666 7788"}
            userEmail={"example@work.com"}
            userRegion={"Central JHB"}
            flag={flag}
          />
        </div>
      )}
    </>
  );
}
