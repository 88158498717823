import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export function CreateButton({ children, onClick, icon, className }) {
  return (
    <button
      onClick={onClick}
      className={`${className} flex p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E]`}
    >
      <span className="hidden md:block md:text-sm text-nowrap font-bold">
        {children}
      </span>{" "}
      {icon}
    </button>
  );
}

export function EditMentorButton({ mentor }) {
  const navigate = useNavigate();

  const hanldeClick = () => {
    if (mentor && mentor.id) {
      navigate(`/manage-mentors/edit-mentor/${mentor.id}`, {
        state: { mentor },
      });
    } else {
      console.error("Mentor data is missing");
    }
  };
  return (
    <button
      onClick={hanldeClick}
      className="rounded-md bg-green-500 md:bg-white border border-gray-200 p-2 hover:bg-green-300"
    >
      <PencilIcon className="size-6 text-sky-100 md:text-[#0B133E]" />
    </button>
  );
}

export function DeleteMentorButton({ mentor }) {
  const navigate = useNavigate();

  const handleClick = () => {
    if (mentor && mentor.id) {
      navigate(`/manage-mentors/delete-mentor/${mentor.id}`, {
        state: { mentor },
      });
    } else {
      console.error("Mentor data missing");
    }
  };
  return (
    <button
      onClick={handleClick}
      className="rounded-md bg-red-500 md:bg-white border border-gray-200 p-2 hover:bg-red-300"
    >
      <TrashIcon className="size-6 text-sky-100 md:text-[#0B133E]" />
    </button>
  );
}

export function ConfirmButton({ children }) {
  return (
    <button
      type="submit"
      className="flex bg-sky-200 p-1.5 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300"
    >
      {children}
    </button>
  );
}
