import React, { useState, useEffect } from "react";
import { DeleteMentorButton, EditMentorButton, CreateButton } from "./buttons";
import Search from "../../../components/search";
import Pagination from "./pagination";
import { Link } from "react-router-dom";
import { UserIcon } from "@heroicons/react/24/outline";

const formatNumberOfStudents = (number) => {
  return number.toLocaleString();
};

const TableRow = ({ mentor }) => (
  <tr className=" bg-white text-[#0B133E] border-b last:border-b-0">
    <td className="py-2 px-2 font-medium text-left text-nowrap">
      {mentor.name}
    </td>
    <td className="py-2 px-4 font-medium text-left">{mentor.email}</td>
    <td className="py-2 px-4 font-medium text-left">{mentor.phone}</td>
    <td className="py-2 px-4 font-medium text-left">
      {formatNumberOfStudents(mentor.students)}
    </td>
    <td className="py-2 px-4 font-medium text-left">R{mentor.earnings}K</td>
    <td className="py-2 px-4 text-left">
      <div className="flex gap-3">
        <EditMentorButton mentor={mentor} />
        <DeleteMentorButton mentor={mentor} />
      </div>
    </td>
  </tr>
);

const ReusableTable = ({ headers, mentors, currentPage, itemsPerPage }) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentMentors = mentors.slice(startIndex, startIndex + itemsPerPage);

  return (
    <>
      <div className="md:hidden text-[#0B133E]">
        {currentMentors.map((mentor) => (
          <div
            key={mentor.id}
            className="mb-2 border border-gray-400 rounded-md bg-white p-4"
          >
            <div className="flex items-center justify-between border-b pb-4">
              <div>
                <div className="mb-2 flex items-center">
                  <p className="text-xl font-medium">{mentor.name}</p>
                </div>
                <p className="text-sm font-medium text-gray-500">
                  {mentor.email}
                </p>
              </div>
            </div>
            <div className="flex items-center justify-between border-b py-5">
              <div className="flex flex-col">
                <p className="font-medium">
                  {formatNumberOfStudents(mentor.students)} Students
                </p>
              </div>
            </div>
            <div className="pt-4 text-sm flex items-center justify-between">
              <div className="flex flex-col">
                <p className="text-sm font-medium text-gray-500">
                  Total Earnings
                </p>
                <p className="text-xl font-medium">R{mentor.earnings}K</p>
              </div>
              <div className="flex gap-3">
                <EditMentorButton mentor={mentor} />
                <DeleteMentorButton mentor={mentor} />
              </div>
            </div>
          </div>
        ))}
      </div>
      <table className="hidden md:table md:border-collapse border rounded-lg overflow-hidden w-full">
        <thead>
          <tr className="bg-sky-300 text-[#0B133E] border-b">
            {headers.map((header, index) => (
              <th
                key={index}
                className={`py-2 px-4 font-semibold text-left ${
                  index === 0 ? "border-r" : "border-r"
                } first:rounded-tl-lg last:rounded-tr-lg`}
              >
                {header}
              </th>
            ))}
            <th className="py-2 px-4 font-semibold text-left">Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentMentors.map((mentor) => (
            <TableRow key={mentor.id} mentor={mentor} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default function MentorsTable() {
  const [data, setData] = useState([]);
  const headers = ["Name", "Email", "Phone", "Students", "Earnings"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/data/mentors.json`);
        if (!response.ok) {
          throw new Error("Response was not ok.");
        }
        const mentorsData = await response.json();
        setData(mentorsData);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = data.filter(
    (mentor) =>
      mentor.name.toLowerCase().includes(searchQuery.toLocaleLowerCase()) ||
      mentor.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      mentor.phone.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <div className="flex items-center justify-between gap-5">
        <Search
          value={searchQuery}
          onChange={(ev) => setSearchQuery(ev.target.value)}
          placeholder={"Search Mentors..."}
        />
        <Link to="/manage-mentors/create-mentor">
          <CreateButton
            className={"bg-sky-200 hover:bg-sky-300"}
            children={"Create Mentor"}
            icon={<UserIcon className="h-6 w-6" style={{ strokeWidth: 2 }} />}
          />
        </Link>
      </div>

      <div className="hidden md:flex text-[#0B133E] overflow-x-auto">
        <ReusableTable
          headers={headers}
          mentors={filteredData}
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
        />
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      <div className="md:hidden mb-10">
        <ReusableTable
          headers={headers}
          mentors={filteredData}
          currentPage={1}
          itemsPerPage={filteredData.length}
        />
      </div>
    </>
  );
}
