import React from "react";
import { Link } from "react-router-dom";

export function AboutUsMain() {
  return (
    <>
      <div className="text-[#0B133E]">
        <div className="pt-5">
          <h1 className="text-2xl font-bold pb-5">About Us</h1>
          <h2 className="font-semibold text-xl pb-1">Welcome to Robotrader</h2>
          <p>
            At Robotrader, we believe in breaking down barriers to financial
            success. Our mission is simple: to democratize access to advanced
            trading technology, making it accessible to everyone, regardless of
            their resources. Whether you're a mentor looking to empower your
            students or a learner eager to dive into the world of algorithmic
            trading, Robotrader is here to make that journey seamless and
            impactful.
          </p>
        </div>

        <div className="py-10">
          <h2 className="font-semibold text-xl pb-1">Our Story</h2>
          <p>
            Robotrader was born from a passion for innovation and education. Our
            founder, Peace TheeCoder, recognized a challenge that many aspiring
            traders face: the high cost and technical demands of traditional
            trading platforms. Determined to make a difference, Peace developed
            Robotrader—a mobile app that transforms powerful PC-based trading
            robots into accessible tools for mobile users. This innovation
            allows anyone, from anywhere, to participate in the dynamic world of
            forex trading.
          </p>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">What We Offer</h2>
          <p>
            Robotrader isn’t just an app, it's a gateway to financial
            independence. We offer mentors a platform to extend their expertise
            beyond traditional boundaries, reaching students who might not have
            access to high-end trading systems. For students, Robotrader
            provides the opportunity to learn and trade using cutting-edge
            algorithms, all from the convenience of their mobile phones.
          </p>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">Why Robotrader?</h2>
          <ul className="list-disc pl-5 flex flex-col gap-3">
            <li>
              <strong>Empowerment: </strong>
              We empower mentors to expand their reach and students to gain
              hands-on experience in algorithmic trading.
            </li>

            <li>
              <strong>Innovation: </strong>
              By converting PC-based trading systems into mobile-friendly
              platforms, we’re revolutionizing the way forex trading is taught
              and practiced.
            </li>
            <li>
              <strong>Accessibility: </strong>
              No PC? No problem. Robotrader brings sophisticated trading tools
              right to your mobile device.
            </li>
          </ul>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">Join the Revolution</h2>
          <p>
            Are you a mentor ready to inspire the next generation of traders? Or
            a student eager to explore the possibilities of algorithmic trading?
            Robotrader is your partner on this exciting journey. Connect with us
            today, and let’s shape the future of trading together.
          </p>
        </div>

        <div className="pb-10">
          <h2 className="font-semibold text-xl pb-1">Get in Touch</h2>
          <p>
            We’re always here to help you on your trading journey. For more
            details, or to start using Robotrader, reach out to us via{" "}
            <Link
              to={"https://wa.me/0614361548"}
              target="_blank"
              className="font-semibold text-sky-800 hover:underline underline-offset-4"
            >
              {" "}
              <em>WhatsApp at +27 61 436 1548.</em>
            </Link>{" "}
            We can’t wait to see what you’ll achieve with Robotrader by your
            side.
          </p>
        </div>
      </div>
    </>
  );
}
