import React from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import Papa from "papaparse"; // Import PapaParse for CSV export
import { CreateButton } from "./buttons";
import { ArrowUpCircleIcon } from "@heroicons/react/24/outline";

// Mock data for illustration purposes
const mockData = [
  { date: "2024-09-01", description: "Sale of Product A", amount: 5000 },
  { date: "2024-09-02", description: "Sale of Product B", amount: 7500 },
  { date: "2024-09-03", description: "Subscription Fee", amount: 2000 },
  { date: "2024-09-04", description: "Service Charge", amount: 1500 },
  { date: "2024-09-05", description: "Sale of Product C", amount: 3000 },
];

export default function ExportButton() {
  // Function to export data as Excel
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(mockData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      "data.xlsx"
    );
  };

  // Function to export data as CSV using PapaParse
  const exportToCSV = () => {
    const csv = Papa.unparse(mockData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "data.csv");
  };

  return (
    <div className="flex justify-end gap-5">
      <CreateButton
        className={"flex flex-row-reverse py-2 bg-sky-200 hover:bg-sky-300"}
        children={"Export to Excel"}
        onClick={exportToExcel}
        icon={
          <ArrowUpCircleIcon className="h-5 w-5" style={{ strokeWidth: 2 }} />
        }
      />

      <CreateButton
        className={"py-2 bg-sky-200 hover:bg-sky-300"}
        children={"Export to CSV"}
        onClick={exportToCSV}
        icon={
          <ArrowUpCircleIcon className="h-5 w-5" style={{ strokeWidth: 2 }} />
        }
      />
    </div>
  );
}
