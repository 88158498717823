import React from "react";
import {
  CreditCardIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
} from "@heroicons/react/24/outline";
import { Link, useParams } from "react-router-dom";
import "../../../auth/index.css";

export default function Payment() {
  const { appname } = useParams();

  const handleSubmit = (ev) => {
    ev.preventDefault();
  };

  return (
    <>
      <div className="bg-image px-5 md:flex md:flex-row-reverse">
        <div className="bg-white md:w-[60%] rounded-xl md:rounded-l-xl md:rounded-none lg:w-[50%]">
          <div className="flex flex-col m-5">
            <div className="font-medium text-[#0B133E] mt-5">
              <div className="px-5">
                <p className="font-semibold">
                  Robotrader License Keys are sold for
                </p>
                <p>$10 per license key</p>
              </div>

              <div className="mt-5 mb-5 px-5">
                <p>
                  <em>
                    <strong>Please Note</strong>
                  </em>
                  , if you purchase <strong>50 or more</strong> license keys,
                  you will be charged <strong>$7</strong> per license key!
                </p>
              </div>
              <div className="flex px-5 lg:mt-5 lg:mr-5">
                <CreditCardIcon className="h-6 w-6" />
                <CreditCardIcon className="h-6 w-6" />
                <CreditCardIcon className="h-6 w-6" />
                <CreditCardIcon className="h-6 w-6" />
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="rounded-md bg-white p-4 md:p-6 font-medium text-[#0B133E] lg:mt-10">
                <h2 className="font-semibold text-xl">Select Payment method</h2>

                <div className="my-5 md:flex justify-between">
                  <div className="pt-3 text-[15px]">
                    <div className="max-w-full gap-2 flex items-center bg-gray-200 py-3 rounded-md text-gray-500 md:w-[325px] lg:w-[585px]">
                      <CreditCardIcon
                        className="h-6 w-6 ml-2"
                        style={{ strokeWidth: 2 }}
                      />
                      <select
                        name="method"
                        id="method"
                        className="p-0.5 rounded-md"
                      >
                        <option value="select-method">Payment Method</option>
                        <option value="paypal">PayPal</option>
                        <option value="credit-card">Credit Card</option>
                      </select>
                    </div>
                  </div>
                </div>

                <label htmlFor="quantity">Quantity</label>
                <div className="relative rounded-md">
                  <input
                    id="quantity"
                    name="quantity"
                    type="number"
                    className="peer block w-full rounded-md border border-gray-200 py-2 mb-2 pl-3 text-sm outline-2 placeholder:text-gray-500 md:w-[325px] lg:w-full"
                    placeholder="10"
                  />
                </div>
              </div>
              <div className="flex gap-5 px-5 md:px-6">
                <Link to={`/${appname}/register/`}>
                  <button className="flex bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300">
                    <ArrowLeftCircleIcon
                      className="h-6 w-6"
                      style={{ strokeWidth: 2 }}
                    />
                    Cancel
                  </button>
                </Link>

                <Link to={`/${appname}/login/`}>
                  <button className="flex flex-row-reverse bg-sky-200 p-1.5 gap-1 px-2 py-1.8 font-semibold items-center rounded-md text-[#0B133E] hover:bg-sky-300">
                    <ArrowRightCircleIcon
                      className="h-6 w-6"
                      style={{ strokeWidth: 2 }}
                    />
                    Purchase
                  </button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
