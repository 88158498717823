import React from "react";
import PageHelmet from "../../../../components/pageHelmet";
import TopNav from "../../components/topNav";
import Footer from "../../components/footer";
import SideNavBarLayout from "../../components/sideNavBarLayout";
import LogoutButton from "../../components/logoutButton";
import AdminSideNav from "./ui/adminSideNav";
import WelcomeCardLayout from "../../components/welcomeCardLayout";
import MobileNavBarLayout from "../../components/mobileNavBarLayout";
import AdminMobileNav from "./ui/adminMobileNav";
import AdminWelcomeCard from "./ui/adminWelcomeCard";
import { OverviewHeading } from "../../components/overviewHeading";
import OverviewCards from "../../components/overviewCards";
import Statistics from "../../components/statistics";
import DashboardPageLayout from "../..";

export default function AdminPage() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Admin"} />

      <TopNav />
      <DashboardPageLayout>
        <div className="flex flex-col lg:flex-row">
          <SideNavBarLayout>
            <AdminSideNav />
            <LogoutButton />
          </SideNavBarLayout>
          <MobileNavBarLayout>
            <AdminMobileNav />
          </MobileNavBarLayout>
          <div className="px-5 mt-[50px] md:mt-[110px] md:px-10 lg:mt-[60px]  md:min-h-screen lg:flex-1">
            <WelcomeCardLayout>
              <AdminWelcomeCard userType={"admin"} username={"James Jacobs"} />
            </WelcomeCardLayout>
            <OverviewHeading />
            <OverviewCards />
            <Statistics />
          </div>
        </div>
      </DashboardPageLayout>
      <Footer />
    </>
  );
}
