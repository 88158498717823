import React from "react";
import {
  AtSymbolIcon,
  DevicePhoneMobileIcon,
  MapPinIcon,
  UserIcon,
} from "@heroicons/react/24/outline";

export default function ProfileBio({
  username,
  userAge,
  userGender,
  userStatus,
  userAddress,
  userRole,
  userEmail,
  userContact,
  userRegion,
  flag,
}) {
  return (
    <>
      <div className="font-semibold text-gray-700 mt-7">
        <h2 className="text-[#0B133E] font-bold text-xl">{username}</h2>
        <div className="flex gap-2">
          <img src={flag} alt="South-Africa" className="w-6 h-6" />{" "}
          <span className="font-bold text-gray-700">|</span>
          {userAddress}
        </div>

        <div className="flex gap-2">
          <p>
            Age: <span>{userAge}</span>
          </p>
          <span className="font-bold text-gray-700">|</span>
          <p>
            Gender: <span>{userGender}</span>
          </p>
          <span className="font-bold text-gray-700">|</span>
          <p>
            Status: <span className="text-green-600">{userStatus}*</span>
          </p>
        </div>

        <div className="flex flex-col gap-5 justify-between lg:flex-row">
          <div className="mt-10 flex flex-col gap-9 lg:w-[388px]">
            <div className="flex justify-between">
              <div className="flex gap-1 items-center">
                <UserIcon className="w-5 h-5" />
                <p>Role:</p>
              </div>
              <p>{userRole}</p>
            </div>

            <div className="flex justify-between">
              <div className="flex gap-1 items-center">
                <AtSymbolIcon className="w-5 h-5" />
                <p>Email:</p>
              </div>
              <p>{userEmail}</p>
            </div>

            <div className="flex justify-between">
              <div className="flex gap-1 items-center">
                <DevicePhoneMobileIcon className="w-5 h-5" />
                <p>Contact:</p>
              </div>
              <p>{userContact}</p>
            </div>

            <div className="flex justify-between">
              <div className="flex gap-1 items-center">
                <MapPinIcon className="w-5 h-5" />
                <p>Region:</p>
              </div>
              <p>{userRegion}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
