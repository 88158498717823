import React from "react";
import PageHelmet from "../../../../../components/pageHelmet";
import TopNav from "../../../components/topNav";
import Footer from "../../../components/footer";
import SideNavBarLayout from "../../../components/sideNavBarLayout";
import LogoutButton from "../../../components/logoutButton";
import MentorSideNav from "../ui/mentorSideNav";
import MobileNavBarLayout from "../../../components/mobileNavBarLayout";
import MentorMobileNav from "../ui/mentorMobileNav";
import DashboardPageLayout from "../../..";
import HelpSummary from "../ui/helpSummary";

export default function MentorHelp() {
  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Mentor"} />
      <DashboardPageLayout>
        <TopNav />
        <div className="flex flex-col lg:flex-row">
          <SideNavBarLayout>
            <MentorSideNav />
            <LogoutButton />
          </SideNavBarLayout>
          <MobileNavBarLayout>
            <MentorMobileNav />
          </MobileNavBarLayout>
          <div className="px-5 mt-[50px] md:mt-[110px] md:px-10 lg:mt-[60px]  md:min-h-screen lg:flex-1">
            <h1 className="text-xl text-[#0B133E] font-semibold pt-5 lg:pt-0">
              Mentor help
            </h1>
            <div className="flex flex-col gap-5 mb-10">
              <HelpSummary />
            </div>
          </div>
        </div>
      </DashboardPageLayout>
      <Footer />
    </>
  );
}
