import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import { useAppContext } from "../context/AppContext";
import { validateUserAccess } from "../utils/validateUserAccess"; 

import "./loader.css";
import { Loader } from "./loader";


const LoggedInUserWrapper = (props) => {
    const { appData, setUserAccessData } = useAppContext();

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        
        const validateAndSetUserAccess= async () => {
            const userAccess = await validateUserAccess();
            if (userAccess) {
              setUserAccessData(userAccess); 
              setIsLoading(false);
              if(!props.loggedin){
                navigate(`/${appData.name}/`); 
                }
            } else {

              setIsLoading(false);
              if(props.loggedin){navigate(`/${appData.name}/login`); }
            }
        };
    
        validateAndSetUserAccess();
      }, [props, appData , setUserAccessData ,navigate]);


      if (isLoading) {
        return (
          <Loader
            className={"h-[100dvh] flex flex-col items-center justify-center gap-2"}
            children={"Loading..."}
          />
        ); 
      }
      
      return (
        <div>
          <Outlet />
        </div>
      );

};
export default LoggedInUserWrapper;

