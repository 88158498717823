import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import PageHelmet from "../../../../../components/pageHelmet";
import TopNav from "../../../components/topNav";
import Footer from "../../../components/footer";
import SideNavBarLayout from "../../../components/sideNavBarLayout";
import LogoutButton from "../../../components/logoutButton";
import MobileNavBarLayout from "../../../components/mobileNavBarLayout";
import AdminMobileNav from "../ui/adminMobileNav";
import AdminSideNav from "../ui/adminSideNav";
import DashboardPageLayout from "../../..";
import MentorsTable from "../ui/mentorsTable";

export default function ManageMentors() {
  const location = useLocation();
  const isNestedRoute = location.pathname.includes("/manage-mentors/");

  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Manage Your Courses"} />

      <TopNav />
      <DashboardPageLayout>
        <div className="flex flex-col lg:flex-row">
          <SideNavBarLayout>
            <AdminSideNav />
            <LogoutButton />
          </SideNavBarLayout>
          <MobileNavBarLayout>
            <AdminMobileNav />
          </MobileNavBarLayout>
          <div className="px-5 mt-[50px] md:mt-[110px] md:px-10 lg:mt-[60px] md:min-h-screen lg:flex-1">
            {isNestedRoute ? (
              <Outlet />
            ) : (
              <>
                <h1 className="text-xl text-[#0B133E] font-semibold pt-5 lg:pt-0">
                  Manage Mentors
                </h1>

                <MentorsTable />
              </>
            )}
          </div>
        </div>
      </DashboardPageLayout>
      <Footer />
    </>
  );
}
