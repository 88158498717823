export const validateUserAccess = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/account/access/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const data = await response.json();

    if (data.status === "success") {
      return data.data; // Return app data if valid
    } else {
      throw new Error(`New error: ${data.message}`);
    }
  } catch (error) {
    console.error(error.message);
    return null; // Return null if app validation fails
  }
};
