import React from "react";
import Search from "../../../components/search";
import { CreateButton } from "../../admin/ui/buttons";
import { Link } from "react-router-dom";
import { PlusIcon } from "@heroicons/react/24/outline";
import EAsTable from "./easTable";
import EACards from "./eaCards";

export default function EAsSummary() {
  return (
    <div className="text-[#0B133E]">
      <div className="flex items-center justify-between gap-5">
        <Search placeholder={"Search EAs..."} />
        <Link to={"/manage-eas/add-ea"}>
          <CreateButton
            className={"bg-sky-200 hover:bg-sky-300"}
            children={"Add EA"}
            icon={<PlusIcon className="h-6 w-6" style={{ strokeWidth: 2 }} />}
          />
        </Link>
      </div>

      <div className="mt-5">
        {/* <h1 className="text-xl font-semibold text-gray-600 mb-10">Total EAs</h1> */}
        <EAsTable />
        <EACards />
      </div>
    </div>
  );
}
