// src/components/AppWrapper.js
import React, { useEffect, useState } from "react";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { useAppContext } from "../context/AppContext";
import { validateApp } from "../utils/validateApp"; // Import the validation utility
import PageNotFound from "./pageNotFound";
import "./loader.css";
import { Loader } from "./loader";

const AppWrapper = () => {
  const { appname } = useParams();
  const { setAppData } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const validateAndSetAppName = async () => {
      if (appname) {
        const appData = await validateApp(appname);
        if (appData) {
          setAppData(appData); 
          setIsLoading(false);
        } else {
          setError("Invalid app name");
          setIsLoading(false);
          navigate("/404"); 
        }
      } else {
        setError("No app name found in the URL");
        setIsLoading(false);
        navigate("/404");
      }
    };

    validateAndSetAppName();
  }, [appname, setAppData, navigate]);

  if (isLoading) {
    return (
      <Loader
        className={"h-[100dvh] flex flex-col items-center justify-center gap-2"}
        children={"Loading..."}
      />
    ); 
  }

  if (error) {
    return (
      <div>
        <PageNotFound />
      </div>
    ); 
  }

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AppWrapper;
