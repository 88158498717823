// src/utils/validateApp.js

export const validateApp = async (appname) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/app/?name=${appname}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        // Check for server errors
        if (response.status === 403) {
          throw new Error('App not found');
        } else if (response.status === 405) {
          throw new Error('Method not allowed');
        } else {
          throw new Error('Failed to validate app');
        }
      }
  
      const data = await response.json();
  
      if (data.status === 'success') {
        return data.data; // Return app data if valid
      } else {
        throw new Error('App not found');
      }
    } catch (error) {
      console.error('App validation failed:', error.message);
      return null; // Return null if app validation fails
    }
  };
  