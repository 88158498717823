export function ConfirmationModal({ handleCancel, handleConfirm, children }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-10 rounded-md shadow-lg">
        <p className="font-semibold text-[#0B133E] text-nowrap">{children}</p>
        <div className="mt-4 flex gap-2 justify-end">
          <button
            onClick={handleConfirm}
            className="bg-green-500 text-sky-100 font-medium px-4 py-2 rounded-md hover:bg-green-600"
          >
            Yes
          </button>
          <button
            onClick={handleCancel}
            className="bg-red-500 text-sky-100 font-medium px-4 py-2 rounded-md hover:bg-red-600"
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
}

export function AlertModal({ handleCancel, children }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
      <div className="bg-white p-10 rounded-md shadow-lg">
        <p className="font-semibold text-[#0B133E] text-nowrap">{children}</p>
        <div className="mt-4 flex gap-2 justify-end">
          <button
            onClick={handleCancel}
            className="bg-green-500 text-sky-100 font-medium px-4 py-2 rounded-md hover:bg-green-600"
          >
            Okay
          </button>
        </div>
      </div>
    </div>
  );
}
