import { topNavLinks } from "./topNav";
import { useState } from "react";
import { Spiral as Hamburger } from "hamburger-react";
import { NavLink } from "react-router-dom";

export function HamburgerNav() {
  const [isOpen, setOpen] = useState(false);
  return (
    <div className="lg:hidden text-sky-100">
      <Hamburger toggled={isOpen} size={35} toggle={setOpen} />

      {isOpen && (
        <div className="fixed inset-0 bg-[#0B133E] bg-opacity-85 z-60 flex justify-center items-center">
          <div className="bg-sky-100 absolute top-[177px] rounded-lg w-[90%] m-auto p-5 text-left md:top-[225px]">
            <ul className="space-y-4">
              {topNavLinks.map((link) => {
                return (
                  <li key={link.name}>
                    <NavLink
                      to={link.href}
                      onClick={() => setOpen((prev) => !prev)}
                      className={({ isActive }) =>
                        `flex items-center justify-between text-[#0B133E] font-normal hover:underline underline-offset-4 ${
                          isActive
                            ? "underline underline-offset-4 font-semibold"
                            : "bg-transparent"
                        }`
                      }
                    >
                      {link.name}
                      {link.icon}
                    </NavLink>
                  </li>
                );
              })}
            </ul>
            <button
              onClick={() => setOpen(false)}
              className="mt-6 text-sky-500 hover:text-sky-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
