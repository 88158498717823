import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";
import AppWrapper from "../components/AppWrapper"; // Import the AppWrapper component

import LoginForm from "../pages/auth/pages/login";
import RegisterForm from "../pages/auth/pages/register";
import ResetPassword from "../pages/auth/pages/reset_password";
import AccountActivation from "../pages/auth/pages/account_activation";
import EmailVerification from "../pages/auth/pages/email_verification";
import PageNotFound from "../components/pageNotFound";
import AdminPage from "../pages/user/pages/admin";
import MentorPage from "../pages/user/pages/mentor";
import AboutUs from "../pages/user/pages/about_us";
import HowToUseOurSystem from "../pages/user/pages/how_to_use_system";
import TermsAndCondition from "../pages/user/pages/terms_conditions";
import Profile from "../pages/user/pages/profile";
import PersonalizeProfile from "../pages/user/pages/profile/pages/personalizeProfile";
import ProfileSettings from "../pages/user/pages/profile/pages/settingsProfile";
import ManageMentors from "../pages/user/pages/admin/pages/manageMentors";
import Earnings from "../pages/user/pages/admin/pages/earnings";
import Uploads from "../pages/user/pages/admin/pages/uploads";
import ManageKeys from "../pages/user/pages/mentor/pages/manageKeys";
import ManageEAs from "../pages/user/pages/mentor/pages/manageEas";
import MentorHelp from "../pages/user/pages/mentor/pages/mentorHelp";
import CreateMentor from "../pages/user/pages/admin/pages/createMentor";
import EditMentor from "../pages/user/pages/admin/pages/editMentor";
import DeleteMentor from "../pages/user/pages/admin/pages/deleteMentor";
import CreateEarningsForm from "../pages/user/pages/admin/pages/createEarnings";
import PurchaseKeys from "../pages/user/pages/mentor/pages/purchaseKeys";
import AddEA from "../pages/user/pages/mentor/ui/addEA";
import EADetails from "../pages/user/pages/mentor/ui/eaDetails";
import EditEA from "../pages/user/pages/mentor/pages/editEa";
import Payment from "../pages/auth/pages/payment/payment";
import ConfirmReset from "../pages/auth/pages/reset_password/confirm_reset/confirmReset";

import LoggedInUserWrapper from "../components/LoggedInUserWrapper";

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<AppWrapper />}>
      <Route path="/:appname">
        <Route element={<LoggedInUserWrapper loggedin={true} />}>
          <Route path="admin" element={<AdminPage />} />
          <Route index element={<MentorPage />} />

          <Route path="earnings" element={<Earnings />}>
            <Route path="create-earnings" element={<CreateEarningsForm />} />
          </Route>

          <Route path="manage-mentors" element={<ManageMentors />}>
            <Route path="create-mentor" element={<CreateMentor />} />
            <Route path="edit-mentor/:id" element={<EditMentor />} />
            <Route path="delete-mentor/:id" element={<DeleteMentor />} />
            {/* Add more nested routes if needed */}
          </Route>

          <Route path="uploads" element={<Uploads />} />

          <Route path="manage-keys" element={<ManageKeys />}>
            <Route path="purchase-keys" element={<PurchaseKeys />} />
          </Route>

          <Route path="manage-eas" element={<ManageEAs />}>
            <Route path="add-ea" element={<AddEA />} />
            <Route path="ea-details" element={<EADetails />}>
              <Route path="edit-ea" element={<EditEA />} />
            </Route>
          </Route>

          <Route path="mentor-help" element={<MentorHelp />} />
          <Route path="profile" element={<Profile />} />
          <Route path="personalization" element={<PersonalizeProfile />} />
          <Route path="settings" element={<ProfileSettings />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="how-to-use-system" element={<HowToUseOurSystem />} />
          <Route path="terms-and-conditions" element={<TermsAndCondition />} />
        </Route>

        {/* Authentication routes */}
        <Route element={<LoggedInUserWrapper loggedin={false} />}>
          <Route path="login" element={<LoginForm />} />
          <Route path="register" element={<RegisterForm />} />
          <Route path="payment" element={<Payment />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="reset-password" element={<ResetPassword />}>
            <Route path=":token" element={<ConfirmReset />} />
          </Route>
          <Route path="account-activation" element={<AccountActivation />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Route>
  )
);
