import React from "react";
import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";

export default function Pagination({ currentPage, totalPages, onPageChange }) {
  return (
    <div className="hidden md:flex justify-center py-5">
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className="px-4 py-2 mx-1 bg-sky-200 text-[#0B133E] hover:bg-sky-300 rounded-md disabled:bg-gray-300"
      >
        <ArrowLeftIcon className="text-[#0B133E] size-6" />
      </button>
      <span className="px-4 py-2 mx-1">
        Page {currentPage} of {totalPages}
      </span>
      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className="px-4 py-2 mx-1 bg-sky-200 text-[#0B133E] hover:bg-sky-300 rounded-md disabled:bg-gray-300"
      >
        <ArrowRightIcon className="text-[#0B133E] size-6" />
      </button>
    </div>
  );
}
