import React from "react";
import { MentorSideNavLinks } from "./mentorSideNavLinks";
import { NavLink } from "react-router-dom";
import LogoutButton from "../../../../user/components/logoutButton";

export default function MentorMobileNav() {
  return (
    <ul className="flex justify-between p-5 md:px-10">
      {MentorSideNavLinks.map((link) => {
        const { icon, href, name } = link;
        return (
          <li key={name}>
            <NavLink
              to={`${href}`}
              className={({ isActive }) =>
                ` flex flex-col text-[#0B133E] text-[10px] font-semibold hover:underline decoration-2 underline-offset-4 items-center  ${
                  isActive ? "underline decoration-2" : "bg-transparent"
                }`
              }
            >
              {icon}
              {name}
            </NavLink>
          </li>
        );
      })}
      <LogoutButton />
    </ul>
  );
}
