import React from "react";
import logo from "../../../assets/img/icons8-logo-100.png";
import { Link, NavLink } from "react-router-dom";
import { topNavLinks as bottomNavLinks } from "./topNav";
import FooterSocialIcons from "./footerSocialIcons";

export default function Footer() {
  const year = new Date();

  return (
    <footer className="text-sky-100 bg-[#0B133E] h-full p-5 md:p-10">
      <div className="flex flex-col gap-5 md:flex-row md:justify-between">
        <div className="flex flex-col gap-2 md:gap-5 md:justify-between">
          <Link to={`/`} className="flex items-center gap-2">
            <img
              src={logo}
              alt="Company Logo"
              className="hover:cursor-pointer w-[40px] bg-white rounded-[50%]"
            />
            <h2 className="hidden lg:block text-sky-100 text-xl font-semibold leading-none">
              Robotrader
            </h2>
          </Link>
          <h2 className="font-semibold text-md">Contact Us</h2>
          <Link
            to={"tel:+27614361548"}
            className="hover:underline underline-offset-4 text-[14px]"
          >
            Call: +27 61 436 1548
          </Link>

          <Link
            to={"mailto:info@robotrader-bot.com"}
            className="hover:underline underline-offset-4 text-[14px]"
          >
            Email: info@robotrader-bot.com
          </Link>
          <Link
            to={"/"}
            className="hover:underline underline-offset-4 text-[14px]"
          >
            Website: www.robotarder-admin.com
          </Link>
        </div>

        <div className="flex flex-col gap-5 md:flex-row md:justify-start lg:gap-20">
          <div className="flex flex-col md:gap-5">
            <h2 className="font-semibold text-md mb-2">Quick Links</h2>
            <ul className="flex gap-4 md:gap-5 flex-col items-start">
              {bottomNavLinks.map((bottomLink) => (
                <li key={bottomLink.name}>
                  <NavLink
                    to={`${bottomLink.href}`}
                    className={({ isActive }) =>
                      `text-sky-100 text-[14px] font-normal flex items-center  ${
                        isActive
                          ? "underline underline-offset-4"
                          : "hover:underline underline-offset-4"
                      }`
                    }
                  >
                    {bottomLink.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex flex-col  md:hidden lg:flex">
            <h2 className="font-semibold text-md mb-2">
              subscribe to our WhatsApp Newsletter
            </h2>
            <p className="pb-3 text-[14px]">
              Stay on track with our latest <br /> tutorials and offers
            </p>
            <div className="relative">
              <input
                className="bg-gray-200 text-[#0B133E] w-[102%] rounded-md py-2 pl-2 flex items-center justify-between font-medium"
                placeholder="0733904458"
              />
              <Link
                to={"https://wa.me/0614361548"}
                target="_blank"
                className="hover:cursor-pointer absolute top-1 right-0 bg-[#25D366] font-semibold rounded-md text-[#0B133E] p-1 hover:bg-[#4fC919]"
              >
                Subscribe Now
              </Link>
            </div>
          </div>
        </div>
      </div>
      <FooterSocialIcons />
      <div className="border-t text-center p-5">
        <p className="text-[11px]">
          &copy; {year.getFullYear()} Robotrader All Rights Reserved
        </p>
        <p className="text-[11px] ">
          <Link to={"/terms-and-conditions"}>
            <span className="md:hover:underline underline-offset-4">
              Terms of use
            </span>
          </Link>{" "}
          &bull;{" "}
          <Link to={"/privacy-policy"}>
            <span className="md:hover:underline underline-offset-4">
              Privacy policy
            </span>
          </Link>{" "}
          &bull;{" "}
          <Link to={"https://peacetheecoder.com/"} target="_blank">
            <span className="md:hover:underline underline-offset-4">
              Designed and coded by Peace TheeCoder
            </span>
          </Link>
        </p>
      </div>
    </footer>
  );
}
