import { useState } from "react";
import Credentials from "../../components/credentials";
import AdminsLink from "../../components/adminsLink";
import { Link, useParams } from "react-router-dom";
import Button from "../../components/buttons";
import PageHelmet from "../../../../components/pageHelmet";
import AuthPageLayout from "../..";

export default function AccountActivation() {
  const { appData } = useParams();
  const [formData, setFormData] = useState({
    email: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: undefined });
  };

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const validationErrors = {};
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required!";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = "Email must be valid!";
    }

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setFormData({ email: "" });
    }
  };

  return (
    <>
      <PageHelmet pageTitle={"Robotrader - Activate Your Account"} />
      <AuthPageLayout>
        <form onSubmit={handleSubmit}>
          <Link to={`${appData.name}/login`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="size-8 text-blue-900 absolute left-7 top-5 md:left-[43%] lg:left-[52%] svg-icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
              />
            </svg>
          </Link>

          <div className="flex flex-col items-center justify-center min-h-[100dvh]">
            <div className="flex flex-col items-center pb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1"
                stroke="currentColor"
                className="size-[71.6%] text-blue-900"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>

              <h1 className="text-center text-xl font-normal">
                Activate your account
              </h1>
            </div>

            <p className="text-center text-[10px] py-[6.6px] lg:px-[111px]">
              Please enter the email address associated with your account.{" "}
              <br /> If the email address provided matches our records, you will
              receive an email with instructions on how to activate your
              account.
            </p>

            <div className="my-[10px] flex gap-1 flex-col w-[300px] md:w-[330px] lg:w-[360px]">
              <div className="flex flex-col">
                <div className="relative h-full w-full">
                  <div className="absolute bg-blue-900 h-[41px] w-[37px] top-0 left-0 rounded-l-lg">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6 absolute left-2 top-[10px] text-white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>
                </div>
                <input
                  onChange={handleChange}
                  value={formData.email}
                  type="email"
                  name="email"
                  required
                  autoComplete="off"
                  placeholder="Email"
                  className="border border-blue-900 w-full p-2 pl-10 rounded-lg mb-1 focus:outline-none focus:ring-1 focus:ring-blue-600"
                />
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>

              <Button>Activate</Button>
              <div>
                <AdminsLink />
                <Credentials />
              </div>
            </div>
          </div>
        </form>
      </AuthPageLayout>
    </>
  );
}
