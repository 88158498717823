import { useState } from "react";
import Credentials from "../../../components/credentials";
import { Link, useParams, useNavigate } from "react-router-dom";
import Button from "../../../components/buttons";
import { useAppContext } from "../../../../../context/AppContext";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { ButtonLoader } from "../../../../../components/buttonLoader";

export default function ConfirmReset() {
  const { appData } = useAppContext();
  const { token } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    password1: "",
    password2: "",
  });

  // console.log(token);

  const [inputErrors, setInputErrors] = useState({});
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (ev) => {
    const { name, value } = ev.target;
    setFormData({ ...formData, [name]: value });
    // Clear errors related to the specific field being typed in
    setInputErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));

    setErrorMessage("");
    setSuccessMessage("");

    // Additional check for password2 to match password1
    if (name === "password2" && value !== formData.password1) {
      setInputErrors((prevErrors) => ({
        ...prevErrors,
        password2: "Password does not match",
      }));
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    const validationErrors = {};

    if (!formData.password1.trim()) {
      validationErrors.password1 = "Password is required!";
    } else if (formData.password1.length < 8) {
      validationErrors.password1 = "Password must be at least 8 characters!";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.password1)) {
      validationErrors.password1 =
        "Password must contain at least one special character!";
    } else if (!/[A-Z]/.test(formData.password1)) {
      validationErrors.password1 =
        "Password must contain at least one uppercase letter!";
    } else if (!/[0-9]/.test(formData.password1)) {
      validationErrors.password1 =
        "Password must contain at least one numeric digit!";
    }

    if (formData.password1 !== formData.password2) {
      validationErrors.password2 = "Password does not match";
    }

    setInputErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL}/authentication/reset-password/`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: token,
              password: formData.password1,
            }), // Send token and password
          }
        );

        const passwordUpdate = await response.json();

        // console.log(passwordUpdate.message);

        if (passwordUpdate.status.toLowerCase() === "success") {
          // On success
          setSuccessMessage(passwordUpdate.message); // Display success message
          setFormData({ password1: "", password2: "" }); // Reset form
          navigate(`/${appData.name}/login/`); // Redirect to login
        } else {
          setErrorMessage(passwordUpdate.message);
        }
      } catch (err) {
        console.error(`Error1: ${err}`);
        setErrorMessage("Unknown Error! Try Again.");
      } finally {
        setIsLoading(false);
      }
      setFormData({ password1: "", password2: "" });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Link to={`/${appData.name}/reset-password`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="size-8 text-blue-900 absolute left-7 top-5 md:left-[43%] lg:left-[52%] svg-icon"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
          />
        </svg>
      </Link>

      <div className="flex flex-col items-center justify-center min-h-[100dvh]">
        <div className="flex flex-col items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1"
            stroke="currentColor"
            className="text-blue-900 size-[50%]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
            />
          </svg>

          <h1 className="text-center text-xl font-normal">
            Enter your new password
          </h1>
        </div>

        {/* Display success msg... */}
        {successMessage && (
          <span
            className={`p-3 text-left text-sky-100 font-medium rounded-xl flex gap-1 my-[10px] flex-col w-[300px] md:w-[330px] lg:w-[360px] bg-green-600
       `}
          >
            {successMessage}
          </span>
        )}

        {/* Or Display success msg... */}
        {errorMessage && (
          <>
            <span
              className={`p-3 text-center text-sky-100 font-medium rounded-xl flex gap-1 my-[10px] flex-col w-[300px] md:w-[330px] lg:w-[360px] bg-red-600
          `}
            >
              {errorMessage}
            </span>
          </>
        )}

        <div className="flex flex-col w-[300px] md:w-[330px] lg:w-[360px] font-medium">
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-0 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                onCopy={(e) => e.preventDefault()} // Prevent copying
                value={formData.password1}
                type={showPassword1 ? "text" : "password"}
                name="password1"
                required
                autoComplete="off"
                placeholder="New Password"
                className="border border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />
              <div
                className="relative top-[-30px] left-[270px] md:top-[-31px] md:left-[18.5rem] lg:left-[20.5rem]"
                onClick={() => setShowPassword1(!showPassword1)}
              >
                {showPassword1 ? (
                  <EyeIcon className="h5 w-5 absolute" />
                ) : (
                  <EyeSlashIcon className="h5 w-5 absolute" />
                )}
              </div>

              {inputErrors.password1 && (
                <span className="error-message">{inputErrors.password1}</span>
              )}
            </div>

            <div className="flex flex-col mt-3">
              <div className="relative h-full w-full">
                <div className="absolute bg-blue-900 h-[41px] w-[37px] top-0 left-0 rounded-l-lg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6 absolute left-2 top-[10px] text-white"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 5.25a3 3 0 0 1 3 3m3 0a6 6 0 0 1-7.029 5.912c-.563-.097-1.159.026-1.563.43L10.5 17.25H8.25v2.25H6v2.25H2.25v-2.818c0-.597.237-1.17.659-1.591l6.499-6.499c.404-.404.527-1 .43-1.563A6 6 0 1 1 21.75 8.25Z"
                    />
                  </svg>
                </div>
              </div>
              <input
                onChange={handleChange}
                value={formData.password2}
                type={showPassword2 ? "text" : "password"}
                name="password2"
                autoComplete="off"
                required
                placeholder="Confirm Password"
                className="border border-blue-900 w-full p-2 pl-10 rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-600"
              />

              <div
                className="relative top-[-30px] left-[270px] md:top-[-30px] md:left-[18.5rem] lg:left-[20.5rem]"
                onClick={() => setShowPassword2(!showPassword2)}
              >
                {showPassword2 ? (
                  <EyeIcon className="h5 w-5 absolute" />
                ) : (
                  <EyeSlashIcon className="h5 w-5 absolute" />
                )}
              </div>

              {inputErrors.password2 && (
                <span className="error-message">{inputErrors.password2}</span>
              )}
            </div>
          </div>

          <p className="text-[14px] my-[5px] text-center p-1"></p>

          <Button children={isLoading ? <ButtonLoader /> : "Confirm Reset"} />

          <div className="flex flex-col my-[10px]">
            <Credentials />
          </div>
        </div>
      </div>
    </form>
  );
}
